import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,
  RadioProps,
  Typography,
  styled,
} from "@mui/material";
import { createRef, useEffect, useState } from "react";
import searchService from "../../services/InsightService";
import { FacetDetails, Facets, SearchContext } from "../../types/search";
import ReferenceCard from "../../components/search/ReferenceCard";
import DocumentSearchUpload from "../../components/search/InsightFindUpload";
import { CitationHighlighter } from "../../components/search/CitationHighlighter";
import { blue, grey, pink } from "@mui/material/colors";
import { WelcomeMessage } from "../../components/search/WelcomeMessage";

const Sidebar = styled(Box)(({ theme }) => ({
  width: "250px",
  padding: "20px",
  borderRadius: "10px",
  marginRight: "20px",
  display: "flex",
  flexDirection: "column",
  boxShadow: `5px 5px 10px #161e2a,
  -5px -5px 10px #202c3e;`,
}));

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderRadius: "10px",
  boxShadow: `5px 5px 10px #161e2a,
  -5px -5px 10px #202c3e;`,
}));

const ParentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  minHeight: "100vh",
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprint js
function BpRadio(props: Readonly<RadioProps>) {
  return (
    <Radio
      disableRipple
      sx={{ paddingY: 1, marginLeft: "2px" }}
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const DocumentSearch = () => {
  const [titleValue, setTitleValue] = useState<string>("");
  const [filters, setFilters] = useState<Facets>();
  const [searchResponse, setSearchResponse] = useState<SearchContext>();
  const [filterChoices, setFilterChoices] = useState<Partial<FacetDetails>>({});
  const [isSearching, setIsSearching] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [citationRefs, setCitationRefs] = useState({});

  const handleCitationClick = (citation) => {
    const ref = citationRefs[citation];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      ref.current.classList.add("blink");
      setTimeout(() => {
        ref.current.classList.remove("blink");
      }, 1000);
    }
  };

  useEffect(() => {
    if (searchResponse?.answer.length > 1) {
      const citationNumbers = [
        //@ts-ignore
        ...searchResponse.answer.matchAll(/\[([0-9]+)\]/g),
      ].map((match) => match[1]);
      const refs = citationNumbers.reduce((acc, number) => {
        acc[number] = createRef();
        return acc;
      }, {});
      setCitationRefs(refs);
    }
  }, [searchResponse]);

  const query = async () => {
    setIsSearching(true);
    setSearchResponse(null);
    const response = await searchService.query(titleValue, filterChoices);
    setSearchResponse(response);
    setIsSearching(false);
  };

  useEffect(() => {
    searchService
      .getFilters()
      .then((filters) => {
        setFilters(filters.facets);
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        setPageLoaded(true);
      });
  }, []);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof FacetDetails
  ) => {
    setFilterChoices((prev) => ({
      ...prev,
      [key]: prev[key] === event.target.value ? "" : event.target.value, // Toggle value or remove if already selected
    }));
  };

  return (
    <Container sx={{ padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          marginY: 3,
          alignItems: "center",
          justifyContent: "center",
          padding: 1.5,
          boxShadow: `5px 5px 10px #161e2b,
          -5px -5px 10px #202c3d;`,
          gap: 1.5,
          borderRadius: 3,
        }}
      >
        <InputBase
          sx={{
            borderRadius: "10px",
            background: "#1b2534",
            outline: 0,
            boxShadow: `inset 5px 5px 10px #161e2b,
          inset -5px -5px 10px #202c3d;`,
            input: { color: "white", outline: 0, boxShadow: "none" },
            color: "white",
            padding: 1.5,
          }}
          placeholder="Enter something to search"
          value={titleValue}
          onChange={(e) => setTitleValue(e.target.value)}
          fullWidth
        />

        <Button
          onClick={query}
          variant="contained"
          color="primary"
          sx={{
            marginBottom: "20px",
            height: "3.2rem",
            padding: 1.5,
            width: "8rem",
            margin: 0,
            "&.Mui-disabled": {
              background: "#2d4866",
              color: "#959595",
            },
          }}
          disabled={titleValue.length < 1}
        >
          Search
        </Button>
      </Box>
      <ParentContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Sidebar>
            <Typography
              variant="h6"
              fontSize={18}
              marginBottom={2}
              color="white"
            >
              Filters
            </Typography>
            {filters &&
              Object.keys(filters).map((key, index) => (
                <FormControl
                  sx={{
                    padding: 0,
                    marginBottom: 0,
                    marginTop: index === 0 ? 0 : 2,
                  }}
                  key={key}
                  component="fieldset"
                  margin="normal"
                >
                  <FormLabel
                    sx={{
                      input: { color: "white" },
                      color: "silver",
                    }}
                    component="legend"
                  >
                    {capitalizeFirstLetter(key)}
                  </FormLabel>
                  <FormGroup>
                    {filters[key].map((value, index) => (
                      <FormControlLabel
                        key={index + value}
                        label={value}
                        checked={filterChoices[key] === value}
                        control={
                          <Checkbox
                            aria-label={key}
                            name={key}
                            value={value}
                            sx={{
                              color: grey[500],
                            }}
                            onChange={(event) =>
                              handleCheckboxChange(
                                event,
                                key as keyof FacetDetails
                              )
                            }
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              ))}
          </Sidebar>
          <Sidebar sx={{ marginTop: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
              <Typography variant="h6" fontSize={18} color="white">
                Upload a file
              </Typography>
              <Typography variant="h6" fontWeight={600} fontSize={12} color="white">
                SEC 10-K filing
              </Typography>
            </Box>
            <DocumentSearchUpload></DocumentSearchUpload>
          </Sidebar>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <MainContainer
            sx={{
              marginBottom: 3,
              background: "linear-gradient(145deg, #393b3d, #233249)",
            }}
          >
            {searchResponse ? (
              <>
                <Typography
                  variant="h6"
                  fontSize={18}
                  marginTop={2}
                  marginLeft={3}
                  color="white"
                >
                  Summary of top filings
                </Typography>
                <Typography
                  sx={{ padding: "20px" }}
                  variant="body1"
                  color="white"
                >
                  <CitationHighlighter
                    onCitationClick={handleCitationClick}
                    text={searchResponse ? searchResponse.answer : ""}
                  />
                </Typography>
              </>
            ) : (
              !isSearching && <WelcomeMessage />
            )}
          </MainContainer>
          <MainContainer
            sx={
              !searchResponse?.context?.length
                ? { paddingY: 0, paddingBottom: 2, boxShadow: "none" }
                : { paddingY: 0, paddingBottom: 2 }
            }
          >
            {searchResponse?.context?.length > 0 && (
              <Typography
                variant="h6"
                fontSize={18}
                marginTop={2}
                marginLeft={3}
                color="white"
              >
                References from the summary
              </Typography>
            )}
            {searchResponse?.context?.map((document, index) => (
              <ReferenceCard
                citationRefs={citationRefs}
                key={index + document.type}
                referenceNum={index + 1}
                reference={document}
                pageContent={document.page_content}
              />
            ))}
          </MainContainer>
          {isSearching && (
            <div id="loader">
              <span></span>
              <span></span>
            </div>
          )}
        </Box>
      </ParentContainer>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!pageLoaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DocumentSearch;
