import React from 'react';
import { ReactChildrenType, UploadDocContextType } from '../../types';
import UploadDocContext from '../UploadDocContext';

interface IUploadDocContextProviderProps {
  value: UploadDocContextType;
  children: ReactChildrenType;
}

const UploadDocContextProvider: React.FC<IUploadDocContextProviderProps> = (
  props: IUploadDocContextProviderProps
) => {
  const { value, children } = props;
  return <UploadDocContext.Provider value={value}>{children}</UploadDocContext.Provider>;
};

export default UploadDocContextProvider;