import {
  Card,
  CardContent,
  Typography,
  Link,
  Box,
  styled,
  Chip,
} from "@mui/material";
import ReadMoreReadLess from "./ReadMoreReadLess";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";

const NeumorphicCard = styled(Card)(({ theme }) => ({
  borderRadius: "20px",
  background: "#1b2534", // Dark background for neumorphic effect
  boxShadow: `inset 5px 5px 8px #161e2a,
  inset -5px -5px 8px #202c3e`,
  margin: "12px 24px",
  color: "#ffffff", // Adjust text color to white for better contrast
}));

const ReferenceCard = ({
  reference,
  pageContent,
  referenceNum,
  citationRefs,
}) => {
  const { source, type, url, page_no, organization, quarter, year } =
    reference.metadata;

  return (
    <NeumorphicCard
      id={`citation-${referenceNum}`}
      ref={citationRefs[referenceNum]}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          padding: 3,
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography
            fontWeight={"bold"}
            variant="caption"
            component="div"
            paddingBottom={1}
          >
            {organization} - {quarter} {year}
          </Typography>
          <Typography variant="caption" component="div" paddingBottom={1}>
            Reference#{referenceNum}
          </Typography>
        </Box>
        <ReadMoreReadLess text={pageContent} maxLength={150} />
        <Typography sx={{ marginBottom: 2 }} variant="caption" color="white">
          Source:
          <Link marginLeft={1} href={url} target="_blank" rel="noopener">
            {source}
          </Link>
        </Typography>
        <Box>
          <Chip
            sx={{ color: "white", textTransform: "capitalize" }}
            size="small"
            icon={<ArticleRoundedIcon color="primary" />}
            label={type}
          />

          <Chip
            sx={{ color: "white" }}
            size="small"
            icon={<AutoStoriesRoundedIcon color="primary" />}
            label={`Page: ${page_no}`}
          />
        </Box>
      </CardContent>
    </NeumorphicCard>
  );
};

export default ReferenceCard;
