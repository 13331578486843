// useLoader.tsx
import { useState } from 'react';

const useLoader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>("");

  const startLoader = () => setIsLoading(true);
  const stopLoader = () => setIsLoading(false);
  const setMessageValue = (message: string) => {setMessage(message)}

  return {
    isLoading,
    startLoader,
    stopLoader,
    setMessageValue,
    message
  };
};

export default useLoader;
