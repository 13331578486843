import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { AppGradientButton } from "../../assets/styled-components/button";
import { HealthCareBaseURL, colors } from "../../constants/constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import { useLoaderContext } from "../../contexts/LoaderContext";
import PatientDetails from "./PatientDetails";
import { TabPanel } from "../../components/healthcare/TabPanel";
import HealthCareApi from "../../api/HealthcareApi";
import MetaDataComponent from "../../components/healthcare/MetaDataComponent";
import { Height } from "@mui/icons-material";
import patientApi from "../../api/PatientApi";
import calculateAge from "../../utils/calculateAge";
import SimpleHeadingAndText from "../../components/healthcare/SimpleHeadingAndText";
import SearchIcon from "@mui/icons-material/Search";
import AccordionList from "../../components/healthcare/AccordianList";
const PatientDescription = () => {
  const [patientDescription, setpatientDescription] = useState<any>();
  const [url, setUrl] = useState<string>("");
  const location = useLocation();
  const { startLoader, stopLoader } = useLoaderContext();
  const navigate = useNavigate();
  const [renderingFileName, setRenderingFile] = useState();
  const patientAPI = patientApi();
  const documentAPI = HealthCareApi();
  const [patientData, setPatientData] = useState<any>();
  const [isMetadataEmpty, setIsMetadataEmpty] = useState(false);
  const [isSummaryEmpty, setIsSummaryEmpty] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [summary, setSummary] = useState(null);
  const [isResponseAvailable, setIsResponseAvailable] = useState(false);
  const [leftTabValue, setLeftTabValue] = useState(0);
  const [rightTabValue, setRightTabValue] = useState(0);
  const [drugData, setDrugData] = useState<any>();
  const [searchTerm, setSearchTerm] = useState("");
  
  async function getCurrentpatientData(patientId: any) {
    const patientData = await patientAPI.fetchPatientInfoById(patientId);
    setPatientData(patientData);
  }
  async function fetchPatientDescription(patient: any) {
    try {
      let patientInfo = {
        patientInfo: `age ${calculateAge(
          patient?.date_of_birth
        )},${patient?.conditions
          .map((data) => data.condition.condition_name)
          .join(", ")}`,
      };

      startLoader();
      const patientDescriptionResponse =
        await patientAPI.fetchPatientDescription(patientInfo);
      setpatientDescription(patientDescriptionResponse);
      setIsResponseAvailable(true);
      setRenderingFile(patientDescriptionResponse.files[0]);
      getPdf(patientDescriptionResponse.files[0]);
      await getMetadata(patientDescriptionResponse.files[0]);
      await getSummary(patientDescriptionResponse.files[0]);
      stopLoader();
      return patientDescriptionResponse;
    } catch (error) {
      console.error("Error fetching patient data by ID:", error);
    }
  }
  async function getPdf(fileName) {
    const response = await documentAPI.fetchSignedURL(fileName);
    setUrl(response);
  }
  useEffect(() => {
    const { patientId } = location.state || {};
    getCurrentpatientData(patientId);
    // getDrugSuggestion(searchTerm)
  }, []);

  useEffect(() => {
    if (patientData?.conditions && patientData?.date_of_birth)
      fetchPatientDescription(patientData);
  }, [patientData]);

  const handleLeftTabChange = (event, newValue) => {
    setLeftTabValue(newValue);
  };

  const handleRightTabChange = (event, newValue) => {
    setRightTabValue(newValue);
  };

  const getDrugSuggestion = async (userInput) => {
    
    startLoader();
    let drugInput = {
      condition: "",
      drug: "",
      reason: "",
    };
    drugInput["drug"] = searchTerm;
    drugInput["condition"] = `${patientData?.conditions
      .map((data) => data.condition.condition_name)
      .join(", ")}`;
    drugInput["reason"] = `${patientData?.visit_reasons[0]?.reason}`;
    console.log("entered getDrug Suggestion");
    try {
      const drugResponse = await patientAPI.fetchDrugResponse(drugInput);
      console.log("rrrrrr", drugResponse);
      setDrugData(drugResponse);
      stopLoader();
    } catch (error) {
      console.error("Error fetching drug suggestion:", error);
    }
  };
  async function getSummary(fileKey) {
    startLoader();
    const summary = await documentAPI.fetchDocumentSummaryfromDb(fileKey);
    if (Object.keys(summary).length == 0) {
      setIsSummaryEmpty(true);
    } else {
      const summaryData = [
        { heading: 'Summary', value: summary?.summary || '' },
        { heading: 'Result', value: summary?.results_and_findings || '' },
        { heading: 'Discussion', value: summary?.discussion_and_interpretation || '' },
        { heading: 'Ethical Consideration', value: summary?.ethical_considerations || '' },
        { heading: 'Funding', value: summary?.funding_and_conflicts_of_interest || '' },
        { heading: 'Review', value: summary?.peer_review || '' },
      ];
      setSummary(summaryData);
    }
    stopLoader();
  }
  async function getMetadata(fileKey) {
    const metadata = await documentAPI.fetchMetadatafromDb(fileKey);
    if (Object.keys(metadata).length == 0) {
      setIsMetadataEmpty(true);
    } else {
        const metadataData = [
          { heading: 'Patient Types', value: metadata?.patient_types || '' },
          { heading: 'Purpose', value: metadata?.purpose || '' },
          { heading: 'Background', value: metadata?.background || '' },
          { heading: 'Key Highlights', value: metadata?.key_highlights || '' },
          { heading: 'Study', value: metadata?.study || '' },
          { heading: 'Data Collection', value: metadata?.data_collection || '' },
          { heading: 'Publication', value: metadata?.publication || '' },
          { heading: 'Conclusion', value: metadata?.conclusion || '' },
        ];
      setMetadata(metadataData);
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: renderingFileName ? "" : "center",
          overflow:'hidden',
        }}
      >
        {/* Left Side with Tabs */}
        {renderingFileName && (
          <Box sx={{ width: "40%", borderRight: "1px solid #ccc",overflowY:'scroll' }}>
            <Tabs
              value={leftTabValue}
              onChange={handleLeftTabChange}
              orientation="horizontal"
              aria-label="basic tabs example"
              sx={{
                "& .MuiTab-root": {
                  color: "white", // Text color for all Tabs
                },
                "& .Mui-selected": {
                  color: "white", // Text color for the selected Tab
                },
              }}
            >
              <Tab label="PDF" id="tab-pdf" aria-controls="tabpanel-pdf" />
              <Tab
                label="Metadata"
                id="tab-metadata"
                aria-controls="tabpanel-metadata"
              />
              <Tab
                label="Summary"
                id="tab-summary"
                aria-controls="tabpanel-summary"
              />
            </Tabs>
            <TabPanel
              value={leftTabValue}
              index={0}
              id="tabpanel-pdf"
              aria-labelledby="tab-pdf"
              style={{ Height: "100vh" }}
            >
              <iframe
                src={url ? url : ``}
                width="100%"
                style={{ height: "100vh" }}
              />
            </TabPanel>
            <TabPanel
              value={leftTabValue}
              index={1}
              id="tabpanel-metadata"
              aria-labelledby="tab-metadata"
            >
              {isMetadataEmpty ? (
                <div>Metadata is not yet Approved by Health Advisor</div>
              ) : (
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px',
                  gap: '10px',
                  background: '#171F2C',
                  padding: '15px 30px',
                  borderRadius: '10px',
                }}
              >
                {metadata?.map((item, index) => (
                  <SimpleHeadingAndText
                    key={index}
                    heading={item.heading}
                    value={item.value}
                    isResponseAvailable={isResponseAvailable}
                  />
                ))}
              </Box>
              )}
            </TabPanel>
            <TabPanel
              value={leftTabValue}
              index={2}
              id="tabpanel-summary"
              aria-labelledby="tab-summary"
            >
              {isSummaryEmpty ? (
                <div>Summary is not yet Approved by Health Advisor</div>
              ) : (
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px',
                  gap: '10px',
                  background: '#171F2C',
                  padding: '15px 30px',
                  borderRadius: '10px',
                }}
              >
                {summary?.map((item, index) => (
                  <SimpleHeadingAndText key={index} heading={item.heading} value={item.value} />
                ))}
              </Box>
              )}
            </TabPanel>
          </Box>
        )}
        {/* Right Side Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "60%",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "95%",
              padding: "16px",
              overflowY: "scroll",
              background: "#171F2C",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: "20px auto",
                gap: "10px",
                background: "#171F2C",
                padding: "15px 30px",
                borderRadius: "10px",
              }}
            >
              <Tabs
                value={rightTabValue}
                onChange={handleRightTabChange}
                orientation="horizontal"
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTab-root": {
                    color: "white",
                  },
                  "& .Mui-selected": {
                    color: "white",
                  },
                }}
              >
                <Tab
                  label="Patient Description"
                  id="tab-patientDesc"
                  aria-controls="tabpanel-patientDesc"
                />
                <Tab
                  label="Drug Search"
                  id="tab-drugSearch"
                  aria-controls="tabpanel-drugSearch"
                />
              </Tabs>

              <TabPanel
                value={rightTabValue}
                index={0}
                id="tabpanel-patientDesc"
                aria-labelledby="tab-patientDesc"
                style={{ height: "100vh" }}
              >
                {patientData && (
                  <>
                    <Box display="flex" alignItems="center" fontSize={"2rem"}>
                      {`${patientData?.first_name} ${patientData?.last_name}`}
                    </Box>

                    <PatientDetails patient={patientData} />
                  </>
                )}
                 <SimpleHeadingAndText
                      heading="Reason for Visit"
                      value={patientData?.visit_reasons[0]?.reason || ""}
                      isResponseAvailable={isResponseAvailable}
                    />
                {isResponseAvailable && (
                  <Box>
                    <Typography sx={{ fontWeight: "500", fontSize: 23 }}>
                      AI Suggestion✨
                    </Typography>
                    <SimpleHeadingAndText
                      heading="Medication plan"
                      value={patientDescription?.response?.medicationPlan || ""}
                      isResponseAvailable={isResponseAvailable}
                    />
                  </Box>
                )}
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  marginTop={3}
                  marginBottom={3}
                >
                  <AppGradientButton
                    width="30%"
                    height="50px"
                    onClick={() => fetchPatientDescription(patientData)}
                  >
                    <Typography
                      sx={{ fontSize: "16px", color: colors.appWhite }}
                    >
                      Regenerate
                    </Typography>
                  </AppGradientButton>
                </Stack>
              </TabPanel>

              <TabPanel
                value={rightTabValue}
                index={1} // Fix index to 1
                id="tabpanel-drugSearch"
                aria-labelledby="tab-drugSearch"
                style={{ height: "100vh" }} // Fix height
              >
                <Box>
                  <TextField
                    label="Search Drugs"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        getDrugSuggestion(searchTerm);
                      }
                    }}
                    sx={{
                      width: "100%",
                      height: "50px",
                      my: "1rem",
                      label: { color: "#fff" },
                      marginBottom: "16px",
                      input: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#444" },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => getDrugSuggestion(searchTerm)}
                            edge="end"
                          >
                            <SearchIcon sx={{ color: "#fff" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {drugData && (
                    <>
                      <SimpleHeadingAndText
                        heading="Drug Description"
                        value={drugData?.output.text || ""}
                        isResponseAvailable={isResponseAvailable}
                      />
                      <AccordionList data={drugData?.references} />
                    </>
                  )}
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PatientDescription;
