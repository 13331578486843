import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppGradientButton } from "../../assets/styled-components/button";
import HealthCareApi from "../../api/HealthcareApi";
import replaceUnderscoresWithSpaces from "../../utils/replaceUnderscoresWithSpaces";
import SimpleHeadingAndText from "./SimpleHeadingAndText";
function MetaDataComponent({
  fileKey,
  metadata,
  setMetadataCbFn,
  isResponseAvailable,
  isMetadataDisable
}) {
  interface MetadataPayload {
    metadata: any;
    fileKey: string;
  }
  const api = HealthCareApi();
  async function approveDocMetadata() {
    try {
      await api.approveMetadata(fileKey, metadata);
    } catch (error) {
      console.error("Error updating the metadata:", error);
      throw error;
    }
  }
  async function getMetadata(fileKey) {
    const metadata = await api.fetchMetadata(fileKey);
    setMetadataCbFn(metadata);
  }

  useEffect(() => {
    console.log("do we need to disable metadata btn ",isMetadataDisable);
  }, [isMetadataDisable]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flex: "1",
        }}
      >
        <Box>
          <Typography
            sx={{
              width: "500px",
              wordWrap: "break-word",
              wordBreak: "break-all",
              overflowWrap: "break-word",
            }}
          >
            Metadata Of {replaceUnderscoresWithSpaces(fileKey)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2, color: "white" }}>
          {!isMetadataDisable && 
           <AppGradientButton
           width={""}
           height={""}
           sx={{ color: "white" }}
           onClick={() => {
             approveDocMetadata();
           }}
          
         >
           Approve Metadata
         </AppGradientButton>
          }
         
          <AppGradientButton
            width={""}
            height={""}
            sx={{ color: "white" }}
            onClick={() => getMetadata(fileKey)}
          >
            Regenerate Metadata
          </AppGradientButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "20px",
          gap: "10px",
          background: "#171F2C",
          padding: "15px 30px 15px 30px",
          borderRadius: "10px",
        }}
      >
        <SimpleHeadingAndText heading="Patient Types" value={metadata?.patient_types || ""} isResponseAvailable={isResponseAvailable} />
        <SimpleHeadingAndText heading="Purpose" value={metadata?.purpose || ""} isResponseAvailable={isResponseAvailable} />
        <SimpleHeadingAndText heading="Background" value={metadata?.background || ""} isResponseAvailable={isResponseAvailable} />
        <SimpleHeadingAndText heading="Key Highlights" value={metadata?.key_highlights || ""} isResponseAvailable={isResponseAvailable}/>
        <SimpleHeadingAndText heading="Study" value={metadata?.study || ""} isResponseAvailable={isResponseAvailable} />
        <SimpleHeadingAndText heading="Data Collection" value={metadata?.data_collection || ""} isResponseAvailable={isResponseAvailable} />
        <SimpleHeadingAndText heading="Publication" value={metadata?.publication || ""} isResponseAvailable={isResponseAvailable} />
        <SimpleHeadingAndText heading="Conclusion" value={metadata?.conclusion || ""} isResponseAvailable={isResponseAvailable} />
      </Box>
    </>
  );
}

export default MetaDataComponent;
