import axios from "axios";
import { HealthCareBaseURL } from "../constants/constants";

const patientApi = () => {

  const fetchPatientInfo = async () => {
    try {
      const response = await axios.get(`${HealthCareBaseURL}patient/list`);
      if (response && response.data) {
        return response.data;
      }
      return {};
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  };

  const fetchPatientInfoById = async (patientId) => {
    try {
      const response = await axios.get(`${HealthCareBaseURL}patient/${patientId}/detail`);
      if (response && response.data) {
        return response.data;
      }
      return {};
    } catch (error) {
      console.error("Error fetching patient data by ID:", error);
      throw error;
    }
  };

  const fetchPatientDescription = async (patientInfo) => {
    try {
      const response = await axios.post(`${HealthCareBaseURL}patient/patient/suggestions`, patientInfo);
      if (response && response.data) {
        return response.data;
      }
      return {};
    } catch (error) {
      console.error("Error fetching description:", error);
      throw error;
    }
  };

  const fetchDrugResponse = async (drugInfo) => {
    try {
      const response = await axios.post(`${HealthCareBaseURL}patient/drug/search`, drugInfo);
      if (response && response.data) {
        return response.data;
      }
      return {};
    } catch (error) {
      console.error("Error fetching description:", error);
      throw error;
    }
  }

  return {
    fetchPatientInfo,
    fetchPatientInfoById,
    fetchPatientDescription,
    fetchDrugResponse
  };
};

export default patientApi;
