import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);


const axiosInstanceWithoutApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const axiosInstanceLocal = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_LOCAL,
});

export { axiosInstance, axiosInstanceWithoutApi ,axiosInstanceLocal};
