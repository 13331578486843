import { Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import React from 'react';

function SimpleHeadingAndText({ heading, value, isResponseAvailable = true }) {
  const renderContent = () => {
    switch (typeof value) {
      case 'string':
        return <Typography>{value}</Typography>;
      case 'object':
        if (Array.isArray(value)) {
          return (
            <List>
              {value.map((item, index) => (
                <ListItem key={index} sx={{ padding: '0px' }}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          );
        } else if (value !== null) {
          return (
            <List>
              {Object.entries(value).map(([key, val], index) => (
                <ListItem key={index} sx={{ padding: '0px' }}>
                  <ListItemText primary={`${key}: ${val}`} />
                </ListItem>
              ))}
            </List>
          );
        }
        return <Typography>Unsupported datatype</Typography>;
      default:
        return <Typography>Unsupported datatype</Typography>;
    }
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {heading}
      </Typography>
      {value ? (
        renderContent()
      ) : (
        isResponseAvailable && (
          <Typography>No information related to this could be found.</Typography>
        )
      )}
      <Divider sx={{ margin: '16px 0', borderColor: 'gray' }} />
    </>
  );
}

export default SimpleHeadingAndText;
