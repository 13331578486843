export const CitationHighlighter = ({ text, onCitationClick }) => {
  const parseText = (text) => {
    const regex = /\[([0-9]+)\]/g;
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This is a citation
        return (
          <a
            style={{
              color: "#709bf9",
              textDecoration: "none",
              textAlign: "justify",
            }}
            href={`#citation-${part}`}
            key={index}
            className="citation-link"
            onClick={(e) => {
              e.preventDefault();
              onCitationClick(part);
            }}
          >
            [{part}]
          </a>
        );
      }
      // This is regular text
      return part;
    });
  };

  return <p>{parseText(text)}</p>;
};
