import { axiosInstance } from "./axios-instance";

const apiRequestInterceptor = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('authorization');
        if(token) {
            config.headers.set('Authorization', `Bearer ${token}`)
        }
      return config;
    },
    (error) => error
  );
};

export { apiRequestInterceptor };
