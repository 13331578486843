// Loader.tsx
import React from 'react';
import {CircularProgress, Backdrop, Typography, Box} from '@mui/material';
import { useLoaderContext } from '../../contexts/LoaderContext';

const Loader: React.FC = () => {
  const { isLoading, message } = useLoaderContext();

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' ,alignItems: 'center' }}>
            <CircularProgress color="inherit" />
            {message && <Typography>{message}</Typography>}
        </Box>

    </Backdrop>
  );
};

export default Loader;
