import React, { useEffect, useState } from "react";
import { Typography, Box, Divider, Button, Avatar } from "@mui/material";
import { Padding } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PatientDescription from "./PatientDescription";
import { AppGradientButton } from "../../assets/styled-components/button";
import { colors } from "../../constants/constants";
import patientApi from "../../api/PatientApi";
import calculateAge from "../../utils/calculateAge";

const PatientDetails = ({ patient }) => {
  const navigate = useNavigate();
  const patientAPI = patientApi();
  const [patientData, setPatientData] = useState<any>();
  
  async function handlePatientClick(patient) {
    const patientDescriptionResponse = await patientAPI.fetchPatientInfoById(patient.patient_id);
    setPatientData(patientDescriptionResponse);
  }

  useEffect(() => {
    handlePatientClick(patient);
  }, []);
  return (
    <>
      <Box style={{ padding: "5px" }}>
       
        <Typography variant="subtitle1" color="#ff">
          {`Gender: ${patientData?.gender}`}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ padding: "3px" }}>
        {`Age : ${calculateAge(patientData?.date_of_birth.split("T")[0])}`}
      </Typography>
    
      <Box m={'1rem'} display="flex">
  <Box flex={1} borderRight="1px solid #ccc" padding="0 1rem">
    <Typography variant="body1" textAlign="center" fontWeight={"bold"} paddingBottom="8px" borderBottom="1px solid #ccc">
      Medication
    </Typography>
    <p style={{  padding: '0', margin: '1rem 0',textAlign:"center" }}>
      {patientData?.medications.map((med) => (
        <li key={med.medication_id} style={{ padding: "4px 0", paddingLeft: '20px' }}>
          {med.medication.medication_name}
        </li>
      ))}
    </p>
  </Box>

  <Box flex={1} borderRight="1px solid #ccc" padding="0 1rem">
    <Typography variant="body1" textAlign="center" fontWeight={"bold"} paddingBottom="8px" borderBottom="1px solid #ccc">
      Allergy
    </Typography>
    <p style={{ padding: '0', margin: '1rem 0' ,textAlign:"center"}}>
      {patientData?.allergies.map((allergy) => (
        <li key={allergy.allergy_id} style={{ padding: "4px 0", paddingLeft: '20px' }}>
          {allergy.allergy.allergy_name}
        </li>
      ))}
    </p>
  </Box>

  <Box flex={1} padding="0 1rem">
    <Typography variant="body1" textAlign="center" fontWeight={"bold"} paddingBottom="8px" borderBottom="1px solid #ccc">
      Condition
    </Typography>
    <p style={{  padding: '0', margin: '1rem 0',textAlign:"center" }}>
      {patientData?.conditions.map((condition) => (
        <li key={condition.condition_id} style={{ padding: "4px 0", paddingLeft: '20px', }}>
          {condition.condition.condition_name}
        </li>
      ))}
    </p>
  </Box>
</Box>


      
    </>
  );
};

export default PatientDetails;
