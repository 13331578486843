import React from "react";
import { ReactChildrenType, AuthContextType } from "../../types";
import AuthContext from "../AuthContext";

interface IAuthContextProviderProps {
  value: AuthContextType;
  children: ReactChildrenType;
}

const AuthContextProvider: React.FC<IAuthContextProviderProps> = (
  props: IAuthContextProviderProps
) => {
  const { value, children } = props;
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;