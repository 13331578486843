import { styled, Button } from "@mui/material";

interface IAppGradientButton {
  width: string;
  height: string;
}

const AppGradientButton = styled(Button)<IAppGradientButton>(
  ({ width, height }) => ({
    width,
    height,
    background:
      "transparent linear-gradient(90deg, #0081CF 0%, #008F7A 100%) 0% 0% no-repeat padding-box",
    borderRadius: "10px",
  })
);

export const AppGradientButtonOutlined = styled(AppGradientButton)<IAppGradientButton>(
  ({ width, height }) => ({
    width,
    height,
    background:
      "linear-gradient(90deg, #0081CF 0%, #008F7A 100%) 0% 0% no-repeat padding-box",
    borderRadius: "10px",
    border: "2px solid", // set your border size here
    backgroundClip: "border-box",
    color: "transparent",
    WebkitBackgroundClip: "text",
    MozBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    MozTextFillColor: "transparent",
    boxShadow: "none",
    
  })
);

export const GhostButton = styled(Button)({
    background: 'transparent',
    color: 'red',
    border: '1px solid white',
    padding: '10px 20px',
    borderRadius: "10px",
    fontSize: '16px',
    
  });
  




export { AppGradientButton };
