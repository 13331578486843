import { Box, Typography } from "@mui/material";

export const WelcomeMessage = () => {
  return (
    <Box sx={{ color: "#fff", padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Welcome to Insight Find, A 10-K Explorer!
      </Typography>
      <Typography variant="body1" paragraph>
        Leverage the power of AI to search and explore insights from 10-K filings. 10-K Explorer is designed to help you quickly and efficiently find the information you need within these comprehensive reports.
      </Typography>
      <Typography variant="h5" gutterBottom>
        How to Get Started:
      </Typography>
      <ul style={{ marginLeft: "30px", marginBottom: "20px" }}>
        <li>
          <Typography variant="body1">
            <strong>Upload 10-K Filings:</strong> Use the "Upload a file" section to add your 10-K documents.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Search with Keywords:</strong> Enter keywords in the search bar to find relevant information within the 10-K filings.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Use Filters:</strong> Narrow down your search results using the filters on the left.
          </Typography>
        </li>
      </ul>
      <Typography variant="h5" gutterBottom>
        Tips for Effective Searches:
      </Typography>
      <ul style={{ marginLeft: "30px", marginBottom: "20px" }}>
        <li>
          <Typography variant="body1">
            Use specific keywords or phrases related to the sections of interest.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Combine filters to refine your results and get more targeted insights.
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        Start uncovering valuable insights from your 10-K filings now!
      </Typography>
    </Box>
  );
};
