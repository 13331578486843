import {axiosInstance} from "../configs/axios-instance";

class SummaryService {
    send = async (fileData:any,query:string[]): Promise<any> => {
        try {
            const formData = new FormData();
            const query_value = { query: query };
            formData.append("file", fileData);
            formData.append("query", JSON.stringify(query_value));
            // const data = { query: query,file: fileData };
            return await axiosInstance.post("/summarize/file-with-query ", formData);
        } catch (err) {
            return err;
        }
    };

    sendURL = async (url: string, query: string[]): Promise<any> => {
        try {
            const data = { query: query, url: url };
            return await axiosInstance.post("/summarize/url", data);
        } catch (err) {
            return err;
        }
    }

    overallSummary = async (fileData:any,query:string): Promise<any> => {
        try {
            const formData = new FormData();
           const value  =  {interest:query}
            console.log(JSON.stringify(value))
            formData.append("file", fileData);
            formData.append("body", JSON.stringify(value));
            // const data = { query: query,file: fileData };
            return await axiosInstance.post("/language/summary", formData);
        } catch (err) {
            return err;
        }
    };



}

const summaryService = new SummaryService();
export default summaryService;
