import React, { useCallback, useState, useEffect } from "react";
import MuiTheme from "./themes/MuiTheme";
import Routes from "./Routes";
import { Box } from "@mui/material";
import {
  AuthContextType,
  LoadingContextType,
  MenuContextType,
  MenuOption,
} from "./types";
import LoadingContextProvider from "./contexts/providers/LoadingContextProvider";
import AuthContextProvider from "./contexts/providers/AuthContextProvider";

import { colors } from "./constants/constants";
import NavBar from "./components/navbar/Navbar";
import MenuContextProvider from "./contexts/providers/MenuContextProvider";
import Loader from "./components/loader/loader";
import { LoaderProvider } from "./contexts/LoaderContext";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    localStorage.getItem("auth") !== null
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [menu, setMenu] = useState<MenuOption>(null);

  const loadSession = useCallback(async (): Promise<void> => {
    try {
      const session = localStorage.getItem("auth");
      setIsAuthenticated(!!session);
    } catch (e) {
      if (e === "No current user") {
        setIsAuthenticated(false);
      }
    }
  }, []);
  useEffect(() => {
    loadSession();
  }, [loadSession]);

  const [loading, setLoading] = useState<boolean>(false);

  const authContextData: AuthContextType = [
    isAuthenticated,
    setIsAuthenticated,
    username,
    setUsername,
    password,
    setPassword,
  ];

  const loadingContextData: LoadingContextType = [loading, setLoading];
  const menuContextData: MenuContextType = [menu, setMenu];

  return (
    <MuiTheme>
      <Box
        sx={{
          background: colors.appBgColor,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AuthContextProvider value={authContextData}>
          <MenuContextProvider value={menuContextData}>
          <LoaderProvider>
              <Loader/> 
              <NavBar />
              <Routes />
            </LoaderProvider>
          </MenuContextProvider>
        </AuthContextProvider>
      </Box>
    </MuiTheme>
  );
};

export default App;
