import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DragAndDrop from "../../components/drag-and-drop/DragAndDrop";
import { AppGradientButton } from "../../assets/styled-components/button";
import {colors, HealthCareBaseURL} from "../../constants/constants";
import UploadDocContextProvider from "../../contexts/providers/UploadContextProvider";
import { UploadDocContextType } from "../../types";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import summaryService from "../../services/summaryService";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLoaderContext } from "../../contexts/LoaderContext";
import { ChatWindow, Containerr, ParentContainer } from "../../components/healthcare/styledContainers";
import {Toaster} from "../../components/toaster/Toaster";


const UploadDoc = () => {
  const [dataFileName, setDataFileName] = useState("");
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [fileData, setFileData] = useState<any>();
  const [fileName, setFileName] = useState<string>("");
  const [error, setError] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [titleValues, setTitleValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorValue, setErrorValue] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  const {startLoader, stopLoader,setMessageValue } = useLoaderContext();
  const handleUploadClick = async () => {
    try {
      startLoader();
      setMessageValue("Document is getting ingested.. Please wait");
      const response = await axios.post(
        `${HealthCareBaseURL}document/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const fileKey = response.data.fileKey;
      if (response.status === 201) {

        setMessage("File uploaded successfully");
        setSuccess(true);
        setTimeout(() => {
          setMessageValue("")
          navigate("/healthcare-metadata", { state: { fileKey } });
          }, 10000);
        // stopLoader()
        
      } else {
        console.log(response);
        setError("File upload failed");
        setSuccess(false);
        stopLoader()
        setOpenPopup(true);
      }
    } catch (error) {
      setError("File upload failed");
      console.log(error);
      setSuccess(false);
      setOpenPopup(true);

      stopLoader()
    }
  };

  const closePopup = () => {
    setOpenPopup(false);
  }


  const handleClose = () => {
    setOpen(false);
    if (success) {
      navigate("/metadata"); // Navigate to the metadata screen on success
    }
  };

  const uploadDocContextData: UploadDocContextType = [
    error,
    setError,
    uploaded,
    setUploaded,
    formData,
    setFormData,
  ];
  useEffect(() => {
    console.log(formData);
  }, [formData, setFormData]);
  const navigate = useNavigate();

  const [websiteUrl, setWebsiteUrl] = useState("");

  const handleDelete = (title) => {
    if (title) {
      setTitleValues((prev) => {
        return prev.filter((value) => value !== title);
      });
    }
  };

  const getSummary = async () => {
    setIsLoading(true);
    const response = await summaryService.send(fileData, titleValues);
    if (response.status === 200) {
      const data = response.data;
      setIsLoading(false);
      navigate("/document-summary-page", { state: data });
    } else {
      setErrorValue(response.data);
      setIsLoading(false);
    }
  };
  const getSummaryFromURL = async () => {
    setIsLoading(true);
    const response = await summaryService.sendURL(websiteUrl, titleValues);
    if (response.status === 200) {
      const data = response.data;
      data.url = websiteUrl;
      console.log(data);
      setIsLoading(false);
      navigate("/document-summary-page", { state: data });
    } else {
      setErrorValue(response.data);
      setIsLoading(false);
    }
  };

  return (
    <>
    <UploadDocContextProvider value={uploadDocContextData}>
      {errorValue && <Alert severity="error">{errorValue}</Alert>}
      <ParentContainer>
        <Containerr>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <ChatWindow>
            <Box
              sx={{
                width: "100%",
                marginBottom: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  flexDirection: "column",
                  marginTop: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "20px",
                    marginBottom: "15px",
                  }}
                >
                  Upload your Doc
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
                  Generate Metadata and brief summary in under a minute!
                </Typography>
              </Box>
            </Box>

            <DragAndDrop
              setDataFileName={setDataFileName}
              setFormData={setFormData}
              setFileName={setFileName}
              fileName={fileName}
              setFileData={setFileData}
            />

            <Stack
              direction="row"
              spacing={1}
              margin={"10px 0px 10px 0px"}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {titleValues.map((title: string) => {
                return (
                  <Chip
                    label={title}
                    variant="outlined"
                    deleteIcon={<CloseIcon style={{ color: "white" }} />}
                    onDelete={() => handleDelete(title)}
                    sx={{ color: "white" }}
                  />
                );
              })}
            </Stack>
              {/* <div style={}> */}
                <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "20px",
                  // border:"1px solid white",
                  padding: "0px 20px 0px 20px",
                }}
              >
                {/*{!error && uploaded && (*/}
                <AppGradientButton
                  width="40%"
                  height="60px"
                  onClick={handleUploadClick}
                >
                  <Typography
                    sx={{ fontSize: "16px", color: colors.appWhite }}
                    
                  >
                    Upload
                  </Typography>
                </AppGradientButton>

                
                {/*)}*/}
              </Box>
              {/* </div> */}
            
            <Toaster openPopup={openPopup} handleClose={closePopup} message={success ? "The document is getting ingested":"Something Went Wrong ... "} isSuccessful={success}/>
            
          </ChatWindow>
        </Containerr>
      </ParentContainer>
    </UploadDocContextProvider>
    </>
  );
};

export default UploadDoc;
