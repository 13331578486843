// LoaderContext.tsx
import React, { createContext, useContext } from 'react';
import useLoader from '../hooks/useLoader';

interface LoaderContextType {
  isLoading: boolean;
  startLoader: () => void;
  stopLoader: () => void;
  message?:string;
  setMessageValue: (message: string) => void;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const loader = useLoader();

  return (
    <LoaderContext.Provider value={loader}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoaderContext = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoaderContext must be used within a LoaderProvider');
  }
  return context;
};
