import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import UploadDocContextProvider from "../../contexts/providers/UploadContextProvider";
import { UploadDocContextType } from "../../types";
import { useNavigate } from "react-router-dom";
import searchService from "../../services/InsightService";
import DragAndDropNeumorphic from "./DragAndDropNeumorphic";
import { UploadSuccessModal } from "./UploadSuccessModal";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "20px 0px 00px 0px",
  width: "100%",
  background: theme.colors.appChatWindowColor,
  borderRadius: "10px",
  alignItems: "center",
}));

const ParentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  // border: "1px solid white",
}));

const DocumentSearchUpload = () => {
  const [fileName, setFileName] = useState("");
  const navigate = useNavigate();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [fileData, setFileData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [dataFileName, setDataFileName] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const uploadDocContextData: UploadDocContextType = [
    error,
    setError,
    uploaded,
    setUploaded,
    formData,
    setFormData,
  ];

  const uploadFile = async () => {
    try {
      setOpen(true);
      searchService
        .ingestFile(fileData)
        .then((res) => {
          if (res.data) {
            setIsSuccessModalOpen(true);
          } else {
            console.log("Ingestion Failed", error);
          }
        })
        .catch((error: Error) => {
          setError(`Ingestion failed : ${error}`);
          setError(error.message);
        })
        .finally(() => {
          setOpen(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <UploadDocContextProvider value={uploadDocContextData}>
      <ParentContainer>
        <Container>
          <DragAndDropNeumorphic
            setFormData={setFormData}
            setFileData={setFileData}
            setFileName={setFileName}
            setDataFileName={setDataFileName}
            fileName={fileName}
          />
        </Container>
        {fileData && (
          <Button
            variant="contained"
            color="primary"
            onClick={uploadFile}
            sx={{ color: "#fff", marginTop: 2, padding: 1.2, width: "100%" }}
          >
            Upload
          </Button>
        )}
        <UploadSuccessModal
          open={isSuccessModalOpen}
          handleClose={() => setIsSuccessModalOpen(false)}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ParentContainer>
    </UploadDocContextProvider>
  );
};

export default DocumentSearchUpload;
