import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";

interface ReadMoreReadLessProps {
  text: string;
  maxLength: number;
}

const ReadMoreReadLess: React.FC<ReadMoreReadLessProps> = ({
  text,
  maxLength,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded || text.length <= maxLength) {
      return text;
    } else {
      return `${text.substring(0, maxLength)}...`;
    }
  };

  return (
    <Box paddingBottom={1}>
      <Typography variant="body2">{renderText()}</Typography>
      {text.length > maxLength && (
        <Button
          size="small"
          onClick={toggleExpand}
          sx={{ textTransform: "none", paddingLeft: 0 }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </Button>
      )}
    </Box>
  );
};

export default ReadMoreReadLess;
