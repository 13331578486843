import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import {CatalogCard} from "../../components/Card/CatalogCard";
import catalogService from "../../services/CatalogService"
import MarkdownIt from "markdown-it";
import {AppGradientButton} from "../../assets/styled-components/button";

export const Catalog = () => {
    const [query,setquery] = useState<string>("");
    const [collection,setCollection] = useState<string>("")
    const [catagory,setCatagory] = useState([])
    const[response,setresponse] = useState<boolean>(false)
    const [isLoading,setIsLoading] = useState(false);

    const getCatalogData = () => {
        if(!query || !collection)
            return
        setresponse(false)
        setIsLoading(true)
        catalogService
            .getCatalogData(query , collection)
            .then((catalog) => {
                setresponse(true)
                if(collection == "ProductFAQ"){
                    const val = catalog.data.data[0]?.answer
                    setCatagory([{answer:val}])
                }else{
                    setCatagory(catalog.data.data)
                }
                setIsLoading(false)
            })
            .catch((err) => {
            console.log(err)
                setIsLoading(false)
        })
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                marginTop: "3%",
                width: "60%"
            }}
        >

            <Typography sx={{fontWeight: "400", fontSize: "22px"}}>
                Search Catalog
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: "10px",
                    width: '100%',
                    marginTop: "3%",
                }}
            >
                <Box sx={{width: "75%"}}>
                    <TextField
                        variant="outlined"
                        placeholder="Search Catalog"
                        value={query}
                        onChange={(e) => setquery(e.target.value)}
                        sx={{
                            width: "100%",
                            borderRadius: "10px",
                            background: "#2F3642",
                            input: {color: "white"},
                            color: "white"
                        }}
                    />
                </Box>
                <Box
                    sx={{width: "25%"}}
                >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"
                                    sx={{color: "white"}}>Options</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={collection}
                            label="Collection"
                            placeholder="Collection"
                            sx={{
                                color: "white",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}
                            onChange={(e:any) => {
                                setCatagory(null)
                                setCollection(e.target.value)
                                console.log(e.target.value)

                            }}
                        >

                            <MenuItem value="ProductsFAQ">Product</MenuItem>
                            <MenuItem value="ProductFAQ">Products FAQ</MenuItem>

                        </Select>
                    </FormControl>
                </Box>

            </Box>

            <Box
                sx={{width: "30%" , marginTop:"3%"}}
            >

                <Button
                    variant="outlined"
                    sx={{
                        color: "white",
                        width: "100%",
                        height: "50px",
                        outlineColor: "white"
                    }}
                     onClick={getCatalogData}
                > Search </Button>
            </Box>

           <Box
               sx={{width: "100%" , marginTop:"3%" }}
           >

               {
                   response && catagory && (catagory.length > 0 ? catagory.map((cat,index) => {
                       console.log("Heelo")
                       return(
                           <CatalogCard key={index} data={cat} collection={collection}/>
                       )
                   })
                   :
                       <Box
                           sx={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               flexDirection: 'column'
                           }}
                       >
                           <Typography sx={{align:"center"}}>No results found</Typography>

                       </Box>
                   )
               }


           </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    )
}
