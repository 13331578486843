import { createContext } from "react";
import { AuthContextType } from "../types";

const AuthContext = createContext<AuthContextType>([
  false,
  null,
  "",
  null,
  "",
  null,
]);

export default AuthContext;
