import React, { Suspense, lazy } from "react";

import { Route, Routes as BrowserRoutes, Navigate } from "react-router-dom";
import useAuthContext from "./hooks/useAuthContext";
import DocumentSummary from "./pages/document-summary/DocumentSummary";
import DocumentSummaryPage from "./pages/document-summary-page/DocumentSummaryPage";
import OverallSummary from "./pages/overall-summary/OverallSummary";
import InsightFind from "./pages/insight-find/InsightFind";
import HealthCareDocs from "./pages/healthcare-docs/HealthCareDocs";
import UploadDoc from "./pages/healthcare-docs/UploadDoc";
import Metadata from "./pages/healthcare-docs/HealthCareDocDetails";
import PatientList from "./pages/patient/PatientList";
import PatientDetails from "./pages/patient/PatientDetails";
import PatientDescription from "./pages/patient/PatientDescription";
import {Catalog} from "./pages/search-catalog/Catalog";

const Home = lazy(() => import("./pages/home/Home"));
const Login = lazy(() => import("./pages/login/Login"));
const NotFound = lazy(() => import("./pages/not-found/NotFound"));
const Chat = lazy(() => import("./components/chat/Chat"));
const Upload = lazy(() => import("./components/upload-doc/Upload"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));

interface IRoutesProps {}

const Routes: React.FC<IRoutesProps> = () => {
  const { isAuthenticated } = useAuthContext();
  return (
    <Suspense fallback={<></>}>
      <BrowserRoutes>
        <Route
          path="/"
          element={!isAuthenticated ? <Login /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/login"
          element={!isAuthenticated ? <Login /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/home"
          element={isAuthenticated ? <Home /> : <Navigate to="/" />}
        />

        <Route
          path="/chat"
          element={isAuthenticated ? <Chat /> : <Navigate to="/" />}
        />
        <Route
          path="/upload-doc"
          element={isAuthenticated ? <Upload /> : <Navigate to="/" />}
        />

        <Route
          path="/settings"
          element={isAuthenticated ? <Settings /> : <Navigate to="/" />}
        />

        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
        />

          <Route
              path="/catalog"
              element={isAuthenticated ? <Catalog /> : <Navigate to="/" />}
          />

        <Route
          path="/document-summary"
          element={isAuthenticated ? <DocumentSummary /> : <Navigate to="/" />}
        />

        <Route
          path="/document-summary-page"
          element={
            isAuthenticated ? <DocumentSummaryPage /> : <Navigate to="/" />
          }
        />

        <Route
          path="/overall-summary"
          element={isAuthenticated ? <OverallSummary /> : <Navigate to="/" />}
        />

        <Route
          path="/insight-find"
          element={isAuthenticated ? <InsightFind /> : <Navigate to="/" />}
        />

        <Route
          path="/healthcare-docs"
          element={isAuthenticated ? <HealthCareDocs /> : <Navigate to="/" />}
        />

        <Route
          path="/upload-healthcare-doc"
          element={isAuthenticated ? <UploadDoc /> : <Navigate to="/" />}
        />

        <Route
          path="/healthcare-metadata"
          element={isAuthenticated ? <Metadata /> : <Navigate to="/" />}
        />
          <Route
          path="/patient-list"
          element={isAuthenticated ? <PatientList /> : <Navigate to="/" />}
        />
        <Route
          path="/patient-description"
          element={isAuthenticated ? <PatientDescription /> : <Navigate to="/" />}
        />
         <Route
          path="/patient-details"
          element={isAuthenticated ? <PatientDetails patient={{
            patient_id: 0,
            first_name: "",
            last_name: "",
            date_of_birth: "",
            gender: "",
            email: "",
            phone_number: "",
            address: "",
            last_visit_date: "",
            doctor_id: 0,
            insurance_id: 0
          }} /> : <Navigate to="/" />}
        />

        <Route path="*" element={<NotFound />} />
      </BrowserRoutes>
    </Suspense>
  );
};

export default Routes;
