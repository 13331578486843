import * as React from "react";
import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppGradientButton } from "../../assets/styled-components/button";
import { colors } from "../../constants/constants";
import replaceUnderscoresWithSpaces from "../../utils/replaceUnderscoresWithSpaces";
import HealthCareApi from "../../api/HealthcareApi";

interface DataItem {
  chunk: string;
  fileName: string;
}

interface AccordionListProps {
  data: DataItem[];
}

const AccordionList: React.FC<AccordionListProps> = ({ data }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const api = HealthCareApi();
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  async function getUrl(fileName: string) {
    const response = await api.fetchSignedURL(fileName);

    window.open(response, "_blank", "noopener");
  }

  return (
    <div>
      {data.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            backgroundColor: colors.appChatWindowColor,
            marginBottom: "10px",
            borderRadius: "4px",
            color: "white",
            "&:before": {
              display: "none",
            },
            overflowY: "scroll",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            style={{
              backgroundColor: colors.appBgColor,
              marginBottom: "10px",
              borderRadius: "4px",
              color: "while",
              borderBottom: "none",
            }}
          >
            <Typography>Reference {index + 1}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: colors.appBgColor,
              borderBottom: "none",
              overflowY: "hidden",
            }}
          >
            <div>
              <Typography>{item.chunk}</Typography>
              <br></br>

              <AppGradientButton
                width={"1"}
                height={""}
                sx={{ color: "white", fontSize: "12px" }}
                onClick={() => getUrl(item.fileName)}
              >
                View File
              </AppGradientButton>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionList;
