import React, { useEffect, useState } from "react";
import DragAndDrop from "../../components/drag-and-drop/DragAndDrop";
import { AppGradientButton, GhostButton } from "../../assets/styled-components/button";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useLoadingContext from "../../hooks/useLoadingContext";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { UploadDocContextType } from "../../types";
import uploadService from "../../services/UploadService";
import { HttpStatus } from "../../constants/constants";
import CloseIcon from "@mui/icons-material/Close";

import { colors } from "../../constants/constants";
import {
  Delete,
  DeleteForeverOutlined,
  DeleteOutline,
  ExpandLess,
  ExpandMore,
  StarBorder,
} from "@mui/icons-material";

import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";

import SendIcon from "@mui/icons-material/Send";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { HealthCareBaseURL } from "../../constants/constants";
import axios from "axios";
import { useLoaderContext } from "../../contexts/LoaderContext";
import Summary from "../../components/buttons/Summary";
import {
  ChatWindow,
  ParentContainer,
  Containerr,
} from "../../components/healthcare/styledContainers";
import HealthCareApi from "../../api/HealthcareApi";
import replaceUnderscoresWithSpaces from "../../utils/replaceUnderscoresWithSpaces";
interface OpenItemsState {
  [key: string]: boolean;
}
const HealthCareDocs = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [fileName, setFileName] = useState<string>("");
  const { setLoading } = useLoadingContext();
  const { width, height } = useWindowDimensions();
  const [dataFileName, setDataFileName] = useState("");
  const [fileData, setFileData] = useState<any>();
  const { startLoader, stopLoader } = useLoaderContext();
  const [summary_content, setSummaryContent] = useState<any>(
    "Fetching Summary...Please Wait!!"
  );
  const [selectedFileKey, setSelectedFileKey] = useState<string>("");
  const [openItems, setOpenItems] = useState<OpenItemsState>({});
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFileNameToDelete, setSelectedFileNameToDelete] = useState('');
  const api = HealthCareApi();
  async function getHealthcareDocs() {
    try {
      startLoader();
      const docs = await api.fetchHealthcareDocs();
      setFiles(docs);
      setSelectedFileKey(docs[0].fileKey);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    } finally {
      stopLoader();
    }
  }
  useEffect(() => {
    getHealthcareDocs();
  }, []);

  useEffect(() => {
    startLoader();
    async function getDocumentSummary(selectedFileKey) {
      startLoader();
      try {
        if (!selectedFileKey) return;
        setSummaryContent("");
        const summary = await api.fetchDocumentSummaryfromDb(selectedFileKey);

        if (Object.keys(summary).length == 0) {
          setSummaryContent("Summary yet to be approved by Health Advisor");
        } else {
          setSummaryContent(summary.summary);
        }
      } catch (error) {
        console.error("Failed to fetch Summary:", error);
        setSummaryContent("Summary yet to be approved by Health Advisor");
      }
    }
    getDocumentSummary(selectedFileKey);
  }, [selectedFileKey]);

  const handleClick = (id, fileKey) => {
    setOpenItemId((prevOpenItemId) => (prevOpenItemId === id ? null : id));
    setSelectedFileKey(fileKey);
  };
  const handleClickOpen = (fileName) => {
    setSelectedFileNameToDelete(fileName);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteFile(selectedFileNameToDelete);
    setOpen(false);
  };
  const [openItemId, setOpenItemId] = useState(null);

  async function deleteFile(fileKey: any) {
    console.log("Function not implemented.");
    try {
      const response = await api.deleteDocument(fileKey);
      getHealthcareDocs();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <ParentContainer>
        <Containerr>
          <ChatWindow>
            <Box sx={{ width: "100%", marginBottom: "15px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  flexDirection: "column",
                  marginTop: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "20px",
                    marginBottom: "15px",
                  }}
                >
                  Health Care Documents
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
                  All documents under one hood!
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "20px",
                padding: "0px 20px 0px 20px",
              }}
            >
              <List
                sx={{ width: "100%", maxWidth: "90%", cursor: "initial" }}
                component="nav"
              >
                {files.map((file) => (
                  <React.Fragment key={file.id}>
                    <ListItemButton>
                      <ListItemIcon>
                        <PictureAsPdfIcon sx={{ color: "white" }} />
                      </ListItemIcon>
                      <ListItemText
                      onClick={() => handleClick(file.id, file.fileKey)}
                        primaryTypographyProps={{ component: "div" }}
                        secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            <Typography>
                              {" "}
                              {file.name}{" "}
                              {file.status === "approved" ? (
                                <CheckCircleIcon
                                  sx={{
                                    fontSize: 16,
                                    backgroundColor: "green",
                                    borderRadius: "12px",
                                  }}
                                />
                              ) : (
                                <CancelIcon
                                  sx={{
                                    fontSize: 16,
                                    backgroundColor: "red",
                                    borderRadius: "12px",
                                  }}
                                />
                              )}{" "}
                            </Typography>
                          </Box>
                        }
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              color="gray"
                            >
                              Filetype: {file.fileType}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              color="gray"
                              sx={{ marginLeft: "8px" }}
                            >
                              Size: {file.size}
                            </Typography>
                          </>
                        }
                      />
                      {openItemId === file.id ? (
                        <ExpandLess
                        />
                      ) : (
                        <ExpandMore
                        />
                      )}
                    </ListItemButton>
                    <Collapse
                      in={openItemId === file.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemText
                            primary={
                              <>
                                {summary_content && (
                                  <Summary summaryContent={summary_content} />
                                )}
                                <Box sx={{display:"flex",justifyContent:'right',}}>
                                <GhostButton
                                  style={{
                                    marginBottom: "1rem",
                                    marginLeft: "1rem",
                                    width:"120px",
                                    height:"30px"
                                  }}
                                  
                                  onClick={() =>
                                    handleClickOpen(file.fileKey)
                                  }
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      color: colors.appWhite,
                                    }}
                                  >
                                    Delete File
                                  </Typography>
                                </GhostButton>
                                <AppGradientButton
                                  style={{
                                    marginBottom: "1rem",
                                    marginLeft: "1rem",
                                  }}
                                  width="120px"
                                  height="30px"
                                  onClick={() =>
                                    navigate("/healthcare-metadata", {
                                      state: {
                                        fileKey: file.fileKey,
                                        source: "db",
                                      },
                                    })
                                  }
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      color: colors.appWhite,
                                    }}
                                  >
                                    View Metadata
                                  </Typography>
                                </AppGradientButton>
                              
                              
                             
                                </Box>
                                <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                sx={{color:'white'}}
                              >
                                <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: colors.appBgColor,color:'white' }}>
                                  {"Confirm Delete"}
                                </DialogTitle>
                                <DialogContent sx={{ backgroundColor: colors.appBgColor,color:'white' }}>
                                  <DialogContentText id="alert-dialog-description" sx={{color:'white',wordWrap:'break-word'}}>
                                    Are you sure you want to delete the file '
                                    {replaceUnderscoresWithSpaces(selectedFileNameToDelete)}'?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ backgroundColor: colors.appBgColor,color:'white' }}>
                                  <Button onClick={handleClose} color="primary">
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={handleConfirmDelete}
                                    color="primary"
                                    autoFocus
                                  >
                                    Confirm
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              </>
                            }
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "20px",
                padding: "0px 20px 0px 20px",
              }}
            >
              <AppGradientButton
                width="40%"
                height="60px"
                onClick={() => navigate("/upload-healthcare-doc")}
              >
                <Typography sx={{ fontSize: "16px", color: colors.appWhite }}>
                  Upload New File
                </Typography>
              </AppGradientButton>
            </Box>
          </ChatWindow>
        </Containerr>
      </ParentContainer>
    </>
  );
};

export default HealthCareDocs;
