import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { assertContextScope } from "../utils/context-utils";

const useAuthContext = () => {
  const context = useContext(AuthContext);
  assertContextScope(context, "AuthContext", "useAuthContext");
  return {
    isAuthenticated: context[0],
    setIsAuthenticated: context[1],
    username: context[2],
    setUsername: context[3],
    password: context[4],
    setPassword: context[5],
  };
};

export default useAuthContext;
