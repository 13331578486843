import {Alert, Backdrop, Box, Button, CircularProgress, styled, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import DragAndDrop from "../../components/drag-and-drop/DragAndDrop";
import {AppGradientButton} from "../../assets/styled-components/button";
import {colors} from "../../constants/constants";
import UploadDocContextProvider from "../../contexts/providers/UploadContextProvider";
import {UploadDocContextType} from "../../types";
import WebScrap from "../../components/web-scrap/WebScrap";
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import summaryService from "../../services/summaryService";
import {useNavigate} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

const label = {inputProps: {'aria-label': 'Checkbox demo'}};


const ChatWindow = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    // overflowY: "scroll",
    width: "96%",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    padding: "30px",
}));

const Container = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    margin: "20px 0px 00px 0px",
    width: "50%",
    background: theme.colors.appChatWindowColor,
    borderRadius: "10px",
    //height: "70vh",
    alignItems: "center",
}));

const ParentContainer = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    // border: "1px solid white",
}));


const OverallSummary = () => {
    const [dataFileName, setDataFileName] = useState("");
    const [formData, setFormData] = useState<FormData>(new FormData());
    const [fileData, setFileData] = useState<any>();
    const [fileName, setFileName] = useState<string>("");
    const [error, setError] = useState('');
    const [uploaded, setUploaded] = useState(false);
    const [checkedLabels, setCheckedLabels] = useState<string[]>([]);
    const [checkedFeatures, setCheckedFeatures] = useState<string[]>([]);
    const [titleValue, setTitleValue] = useState<string>("");
    const [titleValues, setTitleValues] = useState<string[]>([]);
    const [isLoading,setIsLoading] = useState(false);
    const[errorValue,setErrorValue] = useState('')
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [summary, setSummary] = React.useState<{abstractive:"",extractive:""}>();

    const uploadDocContextData: UploadDocContextType = [
        error,
        setError,
        uploaded,
        setUploaded,
        formData,
        setFormData,
    ];
    useEffect(() => {
        console.log(formData)
    }, [formData, setFormData]);
    const navigate = useNavigate();

    const [websiteUrl, setWebsiteUrl] = useState('');


    const userCheckbox = (event) => {
        const {checked, value} = event.target;
        // // If the checkbox was checked, add its label to the array, otherwise remove it
        setCheckedLabels((prev) => {
            if (checked) {
                // Add the label of the checkbox to the array
                return [...prev, value];
            } else {
                // Remove the label of the checkbox from the array
                return prev.filter((label) => label !== value);
            }
        });
    }

    const featureCheckbox = (event) => {
        const {checked, value} = event.target;
        // // If the checkbox was checked, add its label to the array, otherwise remove it
        setCheckedFeatures((prev) => {
            if (checked) {
                // Add the label of the checkbox to the array
                return [...prev, value];
            } else {
                // Remove the label of the checkbox from the array
                return prev.filter((label) => label !== value);
            }
        });
    }

    const addTitleToList = () => {
        if (titleValue !== '') {
            setTitleValues((prev) => {
                return [...prev, titleValue];
            });
            setTitleValue('');
        }
    }

    const handleDelete = (title) => {
        if(title){
            setTitleValues((prev) => {
                return prev.filter((value) => value !== title);
            });
        }
    };

    const getSummary = async () => {
        setIsLoading(true);
        const response = await summaryService.overallSummary(fileData, titleValue)
        if(response.status === 200){
            const data = response.data;
            console.log(data)
            setIsLoading(false);
            setSummary(data?.summary)
            setDeleteDialogOpen(true)
            // navigate('/document-summary-page', {state: data})
        }else{
            setErrorValue(response.data)
            setIsLoading(false)

        }
    }


    const handleDeleteDialogOpen = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };



    const getSummaryFromURL = async () => {
        setIsLoading(true);
        const response = await summaryService.sendURL(websiteUrl, titleValues)
        if(response.status === 200){
            const data = response.data;
            data.url = websiteUrl;
            console.log(data)
            setIsLoading(false);
            navigate('/document-summary-page', {state: data})

        }else{
            setErrorValue(response.data)
            setIsLoading(false)

        }
    }

    return (
        <UploadDocContextProvider value={uploadDocContextData}>
            {errorValue &&  (<Alert severity="error">{errorValue}</Alert>)}
            <ParentContainer>
                <Container>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <ChatWindow>
                        <Box
                            sx={{
                                width: '100%',
                                marginBottom: "15px"
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '15px',
                                    flexDirection: 'column',
                                    marginTop: '15px'
                                }}
                            >
                                <Typography sx={{fontWeight: '400', fontSize: '20px', marginBottom: '15px'}}>
                                    Summario
                                </Typography>
                                <Typography sx={{fontSize: '14px', fontWeight: '300'}}>
                                    Generate concise and informative summaries!
                                </Typography>
                            </Box>
                        </Box>

                        <DragAndDrop setDataFileName={setDataFileName} setFormData={setFormData}
                                     setFileName={setFileName} fileName={fileName}
                                     setFileData={setFileData}/>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: "20px 0px 20px 0px",
                            }}
                        >
                            {/*<Typography>*/}
                            {/*    ---------------- or ----------------*/}
                            {/*</Typography>*/}
                        </Box>

                        {/*<WebScrap websiteUrl={websiteUrl} setWebsiteUrl={setWebsiteUrl} />*/}

                        <Typography
                            sx={{fontSize: '14px', fontWeight: 'bold', textAlign: 'center', marginBottom: "10px"}}> Provide gist of the document to be summarized</Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: "20px",
                                width: '100%'
                            }}
                        >
                            <Box sx={{width: "100%"}}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter title to summarize"
                                    value={titleValue}
                                    onChange={(e) => setTitleValue(e.target.value)}
                                    sx={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        background: "#2F3642",
                                        input: {color: "white"},
                                        color: "white"
                                    }}
                                />
                            </Box>

                            {/*<Box*/}
                            {/*    sx={{width: "20%"}}*/}
                            {/*>*/}
                            {/*    <Button*/}
                            {/*        variant="outlined"*/}
                            {/*        sx={{*/}
                            {/*            color: "white",*/}
                            {/*            width: "100%",*/}
                            {/*            height: "50px",*/}
                            {/*            outlineColor: "white"*/}
                            {/*        }}*/}
                            {/*        onClick={addTitleToList}*/}
                            {/*    > Add </Button>*/}
                            {/*</Box>*/}

                        </Box>

                        <Dialog
                            open={deleteDialogOpen}
                            onClose={handleDeleteDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={{
                                sx: {
                                    background: colors.appBgColor,
                                    color: 'white',
                                    padding: '20px',
                                }
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">
                                Abstractive Summary
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Typography variant="body2" sx={{color: 'white'}}>
                                        {summary?.abstractive}
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogTitle id="alert-dialog-title">
                                Extractive Summary
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Typography variant="body2" sx={{color: 'white'}}>
                                        {summary?.extractive}
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {/*<Button onClick={handleDeleteDialogClose} autoFocus>No</Button>*/}
                                <Button onClick={handleDeleteDialogClose} color="error" >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>



                        {/*<Stack direction="row" spacing={1} margin={"10px 0px 10px 0px"} sx={{display:"flex",flexWrap:"wrap"}}>*/}
                        {/*    {titleValues.map((title: string) => {*/}
                        {/*        return (*/}
                        {/*            <Chip label={title} variant="outlined" deleteIcon={<CloseIcon style={{color:"white"}}/>} onDelete={() => handleDelete(title)} sx={{color:"white",}}/>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</Stack>*/}

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: "20px",
                                // border:"1px solid white",
                                padding: "0px 20px 0px 20px",
                            }}
                        >

                            <Button variant="outlined" sx={{
                                color: "white",
                                width: "40%",
                                height: "60px",
                                outlineColor: "white"
                            }}> Discard </Button>

                            {/*{!error && uploaded && (*/}
                            <AppGradientButton
                                width="40%"
                                height="60px"
                                // sx={{marginTop: '38px'}}
                                // onClick={uploadFile}
                            >
                                <Typography
                                    sx={{fontSize: '16px', color: colors.appWhite}}
                                    onClick={websiteUrl ? getSummaryFromURL : getSummary}>
                                    Apply settings -{'>'}
                                </Typography>
                            </AppGradientButton>
                            {/*)}*/}
                        </Box>


                    </ChatWindow>
                </Container>
            </ParentContainer>


        </UploadDocContextProvider>
    )
}

export default OverallSummary