import axios from "axios";
import {HealthCareBaseURL} from "../constants/constants";
import {useLoaderContext} from "../contexts/LoaderContext";

const HealthCareApi = () => {
  const { startLoader, stopLoader } = useLoaderContext();

  const fetchHealthcareDocs = async () => {
    startLoader();
    try{
      const response = await axios
      .get(`${HealthCareBaseURL}document/list`, {
        headers: {
          organizationid: "222", // replace with organization id
        },
      })
      const documents = response.data.map((doc) => ({
        id: doc.id,
        name: doc.filename,
        fileKey: doc.file_key.split("/").pop() || "",
        fileType: doc.metadata.type.split("/")[1], // Extracting file type from MIME type (doc.metadata.type.split('/')[1])
        size: (doc.metadata.size / 1024).toFixed(2) + "kb", // Converting size to kb((doc.metadata.size / 1024).toFixed(2))
        status: doc.is_ingestion_complete ? "approved" : "rejected",
        summary:
          "According to the search results, moderate consumption of red wine can have several beneficial effects on human health:\n\n- It can increase antioxidant activity and reduce oxidative stress, which can help decrease the risk of cardiovascular diseases and other chronic conditions like diabetes. This is attributed to the high polyphenol content in red wine.\n- It can improve cholesterol levels by reducing LDL (bad) cholesterol and increasing HDL (good) cholesterol. This can lower the risk of heart disease.\n- It has anti-inflammatory effects which can help prevent conditions like atherosclerosis (buildup of plaque in arteries).\n- It can improve glucose metabolism and utilization, lowering the risk of diabetes.\n- It has cardioprotective effects like reducing hypertension, coronary artery disease, and arteriosclerosis. However, the beneficial effects are generally seen with moderate consumption. Excessive alcohol intake can have negative health consequences. The search results indicate that moderate consumption for women is around 0.15 L of red wine per day, and for men it is around 0.2-0.45 L of red wine per day.", // Placeholder summary
      }));
      stopLoader();
      return documents;
    }
    catch(error){
      console.error("Error fetching the docs:", error);
    }
      
  }
  const  fetchDocumentSummary = async (fileKey) => {
    startLoader();
    const response =  await axios
      .get(
        `https://healthcare-genai.poc.app.presidio.com:3000/document/summary/${fileKey}`
      )
      stopLoader();
      if(response && response.data && typeof response.data !== "string"){
        return response.data;
      }else{
        return {
          summary: "We are working on the document. If it takes too long, please consider regenerating.",
          discussion_and_interpretation: "We are working on the document. If it takes too long, please consider regenerating.",
          results_and_findings: "We are working on the document. If it takes too long, please consider regenerating.",
          ethical_considerations:"We are working on the document. If it takes too long, please consider regenerating.",
          funding_and_conflicts_of_interest:"We are working on the document. If it takes too long, please consider regenerating.",
          peer_review:"We are working on the document. If it takes too long, please consider regenerating.",
        };
      }
   
  }
  const  fetchDocumentSummaryfromDb = async (fileKey) => {
    startLoader();
    const response =  await axios
      .get(
        `https://healthcare-genai.poc.app.presidio.com:3000/document/${fileKey}/summary`
      )
      stopLoader();
      if (response && response.data){
        return response.data.summary;
      }
      return {};
    
  }
  const fetchMetadata = async (fileKey) => {
    try {
      startLoader();
      const response = await axios.get(`${HealthCareBaseURL}document/metadata/${fileKey}`);
      stopLoader();
      if(response && response.data && typeof response.data !== "string"){
        return response.data;
      }
      else {

        return {
          patient_types: ["We are working on the document. If it takes too long, please consider regenerating."],
          purpose: "We are working on the document. If it takes too long, please consider regenerating.",
          background: "We are working on the document. If it takes too long, please consider regenerating.",
          key_highlights: ["We are working on the document. If it takes too long, please consider regenerating."],
          conclusion: "We are working on the document. If it takes too long, please consider regenerating.",
          study:"We are working on the document. If it takes too long, please consider regenerating.",
          data_collection:"We are working on the document. If it takes too long, please consider regenerating.",
          publication:"We are working on the document. If it takes too long, please consider regenerating.",
          citations: "We are working on the document. If it takes too long, please consider regenerating.",
        };
      }



    } catch (error) {
      console.error("Error fetching the metadata:", error);
      return {
        patient_types: ["We are working on the document. If it takes too long, please consider regenerating."],
          purpose: "We are working on the document. If it takes too long, please consider regenerating.",
          background: "We are working on the document. If it takes too long, please consider regenerating.",
          key_highlights: ["We are working on the document. If it takes too long, please consider regenerating."],
          conclusion: "We are working on the document. If it takes too long, please consider regenerating.",
          study:"We are working on the document. If it takes too long, please consider regenerating.",
          data_collection:"We are working on the document. If it takes too long, please consider regenerating.",
          publication:"We are working on the document. If it takes too long, please consider regenerating.",
          citations: "We are working on the document. If it takes too long, please consider regenerating.",
      };

    }
  }
  const fetchMetadatafromDb = async (fileKey) => {
    try {
      startLoader();
      const response = await axios.get(`${HealthCareBaseURL}document/${fileKey}/metadata`);
      stopLoader();
      if(response && response.data && response.data.metadata){
      return response.data.metadata;
      
    }
    return {}
      
      
    } catch (error) {
      console.error("Error fetching the metadata:", error);
      throw error;
    }
  }
  const fetchSignedURL = async (fileKey) => {
    const response= await axios
      .post(
        "https://healthcare-genai.poc.app.presidio.com:3000/document/generate-signed-url",
        { fileKey }
      )
    return response.data.url;
  }
  const approveMetadata = async (fileKey,metadata) =>{
    startLoader();
    const response = await axios.put(`${HealthCareBaseURL}document/approved-metadata`, {metadata:metadata,fileKey:fileKey});
    stopLoader();
    return response;
    
  }
  const approveSummary = async (fileKey,summary) =>{
    startLoader();
    const response = await axios.put(`${HealthCareBaseURL}document/approved-summary`, {summary:summary,fileKey:fileKey});
    stopLoader();
    return response;
  }
  const uploadDocument = async (formData) =>{
    const response =  await axios.post(`${HealthCareBaseURL}document/upload`,formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  }
  const deleteDocument = async (fileKey) =>{
    const response =  await axios.delete(`${HealthCareBaseURL}document/delete/${fileKey}`);
    return response;
  }
  return {
    fetchHealthcareDocs,
    fetchDocumentSummary,
    fetchMetadata,
    fetchSignedURL,
    approveMetadata,
    approveSummary,
    fetchDocumentSummaryfromDb,
    fetchMetadatafromDb,
    uploadDocument,
    deleteDocument
  };
}

export default HealthCareApi;
