import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  TextField,
  IconButton,
  Button,
  Collapse,
  ListItemButton,
  ListItemIcon,
  styled,
  Avatar,
} from "@mui/material";
import axios from "axios";
import PatientDetails from "./PatientDetails";
import { ExpandLess, ExpandMore, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { inherits } from "util";
import { AppGradientButton } from "../../assets/styled-components/button";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { colors } from "../../constants/constants";
import { useLoaderContext } from "../../contexts/LoaderContext";
import { ChatWindow, Containerr, ParentContainer } from "../../components/healthcare/styledContainers";
import patientApi from "../../api/PatientApi";
interface Patient {
  patient_id: number;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender: string;
  email: string;
  phone_number: string;
  address: string;
  last_visit_date: string;
  doctor_id: number;
  insurance_id: number;
}
interface OpenItemsState {
  [key: string]: boolean;
}
const PatientList = () => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<any | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const patientId = 1;

  const { startLoader, stopLoader } = useLoaderContext();
  const api = patientApi();

  async function getPatientInfo(){
    const patientResponse = await api.fetchPatientInfo();
    setPatients(patientResponse);
  }
  useEffect(() => {
    getPatientInfo();
  }, []);

  const filteredPatients = patients.filter((patient) =>
    `${patient.first_name} ${patient.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
 
  const [openItems, setOpenItems] = useState<OpenItemsState>({});

  const handleClick = (item) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [item]: !prevOpenItems[item],
    }));
  };
  return (
    <>
     <ParentContainer>
        <Containerr>
          <ChatWindow>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  flexDirection: "column",
                  marginTop: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "20px",
                    marginBottom: "15px",
                  }}
                >
                  Today's patient Appointments
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "300" }}>
                  Get to know your patient before appointment!
                </Typography>
                <TextField
                  label="Search Patients"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    width: "600px",
                    height: "50px",
                    my: "1rem",
                    label: { color: "#fff" },
                    marginBottom: "16px",
                    input: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#444" },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "0px 20px 0px 20px",
              }}
            >
              <List sx={{ width: "100%", maxWidth: "90%" }} component="nav">
                {filteredPatients.map((patient) => (
                  <React.Fragment key={patient.patient_id}>
                    <ListItemButton
                      onClick={() => handleClick(patient.patient_id)}
                    >
                      <ListItemIcon>
                        <PictureAsPdfIcon sx={{ color: "white" }} />
                      </ListItemIcon>
                      <ListItemText
                      primaryTypographyProps={{ component: 'div' }}
                      secondaryTypographyProps={{ component: 'div' }}
                        primary={
                          <Box display="flex" alignItems="center">
                            {`${patient.first_name} ${patient.last_name}`}
                          </Box>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="gray"
                            >
                              Last Visited:{" "}
                              {patient.last_visit_date.split("T")[0]}
                            </Typography>
                          </React.Fragment>
                        }
                      
                      />
                      <AppGradientButton
                        style={{
                          margin:"1rem"
                        }}
                        width="150px" //120px
                        height="30px"
                        onClick={() =>
                          navigate("/patient-description", {
                            state: { patientId: patient.patient_id },
                          })
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: colors.appWhite,
                          }}
                        >
                          AI Suggestions ✨
                        </Typography>
                      </AppGradientButton>
                      {openItems[patient.patient_id] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>
                    <Collapse
                      in={openItems[patient.patient_id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText
                            primary={
                              <>
                                <PatientDetails patient={patient} />
                              </>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "20px",
                padding: "0px 20px 0px 20px",
              }}
            ></Box>
          </ChatWindow>
        </Containerr>
      </ParentContainer>
    </>
  );
};

export default PatientList;
