import {axiosInstance} from "../configs/axios-instance";
class CatalogService {
    async getCatalogData(query,collection): Promise<any> {
        try {
            let prompt = ""
            let return_type = ""
            if(collection == "ProductFAQ"){
                prompt ="This is my query: "+ query +" Based on my query, provide a summary of the relevant information in no more than 10 lines. If no context is provided, respond with 'I don't have sufficient information to provide a response"
                return_type = "string"
            }else{
                prompt = "This is my query: "+query+ "Based on my query, filter the result and provide the json without removing any fields inside it.If no context is provided,respond with empty array "
                return_type = "json"
            }
            const response = await axiosInstance.post('/scrape/query', { collection_id: collection, query:query,prompt:prompt,return_type:return_type });
            console.log(response);
            return response;
        } catch (err) {
            throw err;
        }
    }
}




const catalogService = new CatalogService();
export default catalogService;
