import { styled, Box } from "@mui/material";

interface IInputContainer {
  width: string;
  height: string;
}
const InputContainer = styled(Box)<IInputContainer>(({ width, height }) => ({
  width,
  height,
  background: "#FFFFFF1A 0% 0% no-repeat padding-box",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
}));

const Input = styled("input")(({ theme }) => ({
  outline: "none",
  border: "none",
  background: "none",
  color: theme.colors.appWhite,
  fontWeight: "500",
  fontSize: "16px",
  width: "80%",
  height: "20px",
  "::placeholder": {
    fontSize: "16px",
    fontWeight: "500",
    color: theme.colors.appWhite,
  },
}));

export { InputContainer, Input };
