import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { colors } from "../../constants/constants";
import { AppGradientButton } from "../../assets/styled-components/button";
import { useNavigate } from "react-router-dom";

export function UploadSuccessModal({ open, handleClose }) {
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          background: colors.appBgColor,
          color: "white",
          padding: "20px",
        },
      }}
    >
      <DialogTitle
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CheckCircleOutlineIcon
          sx={{ color: "#00E676", fontSize: "2rem", mr: 1 }}
        />
        <Typography variant="h6">Success!</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" align="center">
          Document has been successfully queued to processing. It will be ready
          within minutes.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pt: 2 }}>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            color: "white",
            width: "40%",
            height: "60px",
            border: "none",
            boxShadow: `5px 5px 10px #161e2b,
            -5px -5px 10px #202c3d;`,
          }}
        >
          Back to search
        </Button>
      </DialogActions>
    </Dialog>
  );
}
