import React from "react";
import { ReactChildrenType, MenuContextType } from "../../types";
import MenuContext from "../MenuContext";

interface IMenuContextProviderProps {
  value: MenuContextType;
  children: ReactChildrenType;
}

const MenuContextProvider: React.FC<IMenuContextProviderProps> = (
  props: IMenuContextProviderProps
) => {
  const { value, children } = props;
  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export default MenuContextProvider;
