import {axiosInstance} from "../configs/axios-instance";

class SettingsService {
    async getSettings(): Promise<any> {
        try {
            const response = await axiosInstance.get('/settings');
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getUsers(orgId): Promise<any> {
        try {
            const response = await axiosInstance.get(`/users/${orgId}`);
            return response;
        } catch (err) {
            throw err;
        }
    }

    async updateSettings(settingsId:string,settings: any): Promise<any> {
        try {
            const response = await axiosInstance.put(`/settings/${settingsId}`, settings);
            return response;
        } catch (err) {
            throw err;
        }
    }

    async deleteKnowledgeBase(): Promise<any> {
        try {
            const response = await axiosInstance.delete('/settings/index');
            return response;
        }
        catch (err) {
            throw err;
        }
    }



    async getOrganizations(): Promise<any> {
        try {
            return await axiosInstance.get('/organization/list');
        } catch (err) {
            throw err;
        }
    }

    async getOrganizationById(organizationId: string): Promise<any> {
        try {
            return await axiosInstance.get(`/organization/get/${organizationId}`);
        } catch (err) {
            throw err;
        }
    }

}

const settingsService = new SettingsService();
export default settingsService;
