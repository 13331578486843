import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useState} from "react";
import {colors} from "../../constants/constants";


type Props = {
    openPopup:boolean;
    handleClose:() => void;
    message:string;
    isSuccessful:boolean;
}


export const Toaster = ({openPopup , message , handleClose,isSuccessful}:Props) => {
    return(
        <Dialog open={openPopup} onClose={handleClose} >
            <div style={{background:colors.appMessageCardColor , width:"500px"}} >
            <DialogTitle color={colors.appWhite}>{isSuccessful ? "Successful" : "Error"}</DialogTitle>
            <DialogContent >
                <p style={{color:"white"}}>{message}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
            </div>
        </Dialog>
    )
}

