import { axiosInstance } from "../configs/axios-instance";
import { FacetDetails, Filters, SearchContext } from "../types/search";

class SearchService {
  async ingestFile(file: File): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      console.log(file);
      const userData = JSON.parse(localStorage.getItem("userData"));

      if (userData) {
        const orgId = userData["org_id"];
        formData.append("body", JSON.stringify({ org_id: orgId }));
      } else {
        throw Error("Org Id missing");
      }

      const response = await axiosInstance.post("/search/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      return response;
    } catch (err) {
      throw err;
    }
  }

  query = async (query: any, facets: Partial<FacetDetails>): Promise<SearchContext> => {
    try {
      const data = {
        query: query,
        filters: facets,
      };

      return (await axiosInstance.post("/search/qa", data)).data;
    } catch (err) {
      return err;
    }
  };

  getFilters = async (): Promise<Filters> => {
    try {
      return (await axiosInstance.get("/search/filters")).data;
    } catch (err) {
      return err;
    }
  };
}

const searchService = new SearchService();

export default searchService;
