
export const HealthCareBaseURL = "https://healthcare-genai.poc.app.presidio.com:3000/";
// export const HealthCareBaseURL = "http://localhost:3000/"
export const HttpStatus = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UN_AUTHORIZED: 401,
  FORBIDDEN: 403,
  NO_CONTENT_SUCCESS: 204,
  CONFLICT: 409,
  ACCEPTED: 202,
  UN_AVAILABLE: 503,
};

export const colors = {
  appBlue: "#00305F",
  appWhite: "#FFFFFF",
  appNavBgColor: "#1B2534",
  appBgColor: "#1B2534",
  appGery: "#FFFFFF1A",
  appFooterColor: "#FFFFFF6F",
  appGreen: "#008D85",
  appError: "#F78888",
  appChatWindowColor: "#171F2C",
  appMessageCardColor: "#283446",
};
