import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../constants/constants';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import useLoadingContext from '../../hooks/useLoadingContext';
import { Input, InputContainer } from '../../assets/styled-components/input';
import LinkIcon from '@mui/icons-material/Link';

type props = {
    websiteUrl: string;
    setWebsiteUrl: (url: string) => void;
    isDisabled?: boolean;
}

const WebScrap = ({websiteUrl, setWebsiteUrl, isDisabled}:props) => {
  const navigate = useNavigate();
  const { setLoading } = useLoadingContext();
  const [error, setError] = useState<string>("");
  const { width, height } = useWindowDimensions();


  console.log(width, height);
  // const scrapContent =  async () => {
  //   try{
  //     if(websiteUrl!==''){
  //       setLoading(true);
  //       const response = await webScrapService.webscrap(websiteUrl);
  //       setLoading(false);
  //       if (response.status === HttpStatus.OK) {
  //         navigate('/chat');
  //       } else{
  //         setError(response.response.data?.detail);
  //       }
  //     }
  //     else{
  //       setError("Please enter a url");
  //     }
  //
  //   }
  //   catch(err){
  //     console.log(err);
  //   }
  // }

  return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
         // marginTop: '10%',
           width: '100%',
        }}
      >
        {/*<Box*/}
        {/*  sx={{ display: 'flex', alignItems: 'center', marginBottom: '26px' }}*/}
        {/*>*/}
        {/*  <Typography sx={{ fontWeight: '400', fontSize: '22px' }}>*/}
        {/*    Hello,&nbsp;*/}
        {/*  </Typography>*/}
        {/*  <Typography sx={{ fontSize: '22px', fontWeight: '300' }}>*/}
        {/*    I am Bedrock AI!*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        {/*<Box sx={{ marginBottom: '55px' }}>*/}
        {/*  <Typography*/}
        {/*    sx={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }}*/}
        {/*  >*/}
        {/*    Join us on this exciting journey as we harness the potential of AI*/}
        {/*    to revolutionize the way we interact and learn.*/}
        {/*  </Typography>*/}
        {/*</Box>*/}

        <InputContainer sx={{ margin: "10px" }} width="100%" height="54px">
          <LinkIcon sx={isDisabled? { opacity: "20%", fontSize: "20px", margin: "0px 15px 0px 20px" }:{ fontSize: "20px", margin: "0px 15px 0px 20px" }} />
          <Input
              disabled={isDisabled}
            value={websiteUrl}
              sx={isDisabled? { opacity: "20%" }:{}}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            placeholder="Enter Public Website URL"
          />
        </InputContainer>
          <Typography
            sx={{ fontSize: '14px', fontStyle: 'italic', textAlign: 'center' }}
          >
            *Only Public Websites are supported
          </Typography>
        {error && (
          <Typography
            sx={{ color: colors.appError, fontSize: "13px", fontWeight: "300" }}
          >
            {error}
          </Typography>
        )}
      {/*  <AppGradientButton*/}
      {/*  width="100%"*/}
      {/*  height="60px"*/}
      {/*  sx={{ marginTop: "38px" }}*/}
      {/*  // onClick={scrapContent}*/}
      {/*>*/}
      {/*  <Typography sx={{ fontSize: "16px", color: colors.appWhite }}>*/}
      {/*    Submit*/}
      {/*  </Typography>*/}
      {/*</AppGradientButton>*/}
      </Box>
  );
};

export default WebScrap;
