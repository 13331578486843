import { useContext } from "react";
import LoadingContext from "../contexts/LoadingContext";
import { assertContextScope } from "../utils/context-utils";

const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  assertContextScope(context, "LoadingContext", "useLoadingContext");
  return {
    loading: context[0],
    setLoading: context[1],
  };
};

export default useLoadingContext;
