import { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "../../constants/constants";
import useUploadDocContext from "../../hooks/useUploadDocContext";

interface IContainer {
  active: boolean;
}
const Container = styled(Box)<IContainer>(({ active }) => ({
  width: "100%",
  height: "156px",
  background: "#1b2534 0% 0% no-repeat padding-box",
  boxShadow: `inset 5px 5px 10px #161e2b,
          inset -5px -5px 10px #202c3d;`,
  borderRadius: "10px",
  cursor: "pointer",
  border: active && "1px solid green",
  display: "flex",
  justifyContent: "center",
  padding: 1,
}));

const UploadedContainer = styled(Box)(({}) => ({
  width: "100%",
  height: "156px",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  background: "#FFFFFF1A 0% 0% no-repeat padding-box",
}));

const FileConatiner = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "10px",
  background: "#00000083 0% 0% no-repeat padding-box;",
}));

const DragAndDropNeumorphic = ({
  setFileName,
  fileName,
  setDataFileName,
  setFormData,
  setFileData,
}: any) => {
  const [dragActive, setDragActive] = useState(false);
  const {
    error,
    setError,
    uploaded,
    setUploaded,
    // setFormData,
  } = useUploadDocContext();
  const [fileSize, setFileSize] = useState("");

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleFileSize = (fileSizeInBytes: number) => {
    const fileSizeInKB = fileSizeInBytes / 1024;
    const fileSizeInMB = fileSizeInKB / 1024;
    if (fileSizeInMB >= 1) {
      setFileSize(`${fileSizeInMB.toFixed(2)} MB`);
    } else if (fileSizeInKB >= 1) {
      setFileSize(`${fileSizeInKB.toFixed(2)} KB`);
    } else {
      setFileSize(`${fileSizeInBytes} bytes`);
    }
  };

  const formatFilename = (filename) => {
    const extension = filename.split(".").pop();
    const nameWithoutExtension = filename.substring(
      0,
      filename.lastIndexOf(".")
    );
    const firstFiveLetters = nameWithoutExtension.substring(0, 15);
    const lastTwoLetters = nameWithoutExtension.substring(
      nameWithoutExtension.length - 2
    );
    return `${firstFiveLetters}...${lastTwoLetters}.${extension}`;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    const fileType = file.type;
    const formData = new FormData();
    formData.append("file", file);
    setFormData(formData);
    handleFileSize(file.size);
    if (
      fileType === "application/pdf" ||
      fileType === "application/vnd.ms-excel" ||
      fileType === "text/csv" ||
      fileType === "text/plain"
    ) {
      setFileName(file.name);
      setUploaded(true);
      setError("");
    } else {
      setFileName("File name.format");
      setUploaded(true);
      setError("Incorrect File Format");
    }
  };

  const handleCancel = () => {
    setUploaded(false);
    setFileData(null);
    setFileName("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFileSize(file.size);
    const fileType = file.type;
    const formData = new FormData();
    formData.append("file", file);
    setFormData(formData);
    setFileData(file);
    console.log(fileType);
    if (
      fileType === "application/pdf"
      // fileType === "application/vnd.ms-excel" ||
      // fileType ===
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      // fileType === "text/csv" ||
      // fileType === "text/plain"
    ) {
      setFileName(file.name);
      setDataFileName(file.name);
      setUploaded(true);
      setError("");
    } else {
      setUploaded(true);
      setFileName("File name.format");
      setError("Incorrect File Format");
    }
  };

  return (
    <>
      {uploaded ? (
        <UploadedContainer>
          <FileConatiner>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "column",
                // border:"1px solid white"
              }}
            >
              <AttachmentIcon sx={{ fontSize: "20px" }} />
              <Tooltip title={fileName} placement="top">
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "300",
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                >
                  {fileName && formatFilename(fileName)}
                </Typography>
              </Tooltip>
              <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                {error ? null : fileSize}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                position: "relative",
                height: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {error ? null : (
                <CancelIcon
                  sx={{
                    fontSize: "20px",
                    color: colors.appGreen,
                    cursor: "pointer",
                    position: "absolute",
                    right: 6,
                    top: 6,
                    marginLeft: "15px",
                  }}
                  onClick={handleCancel}
                />
              )}
            </Box>
          </FileConatiner>
          {error && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <Typography
                sx={{
                  color: colors.appError,
                  fontSize: "13px",
                  fontWeight: "300",
                }}
              >
                {error}
              </Typography>
            </Box>
          )}
        </UploadedContainer>
      ) : (
        <Container
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          active={dragActive}
        >
          <label htmlFor="file-input" style={{ cursor: "pointer" }}>
            <input
              type="file"
              style={{
                width: "0px",
              }}
              id="file-input"
              accept=".pdf,.xlsx,.csv,.txt"
              onChange={handleFileChange}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CloudUploadIcon sx={{ fontSize: "40px", marginTop: 1 }} />
              <Box className="drag-drop-content">
                {fileName ? (
                  <Typography sx={{ fontSize: "16px", fontWeight: "300" }}>
                    {fileName}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "300",
                      marginBottom: "20px",
                      textAlign: "center",
                      paddingX: 4,
                      paddingY: 2,
                    }}
                  >
                    Drag & Drop or Click to Select File
                  </Typography>
                )}
              </Box>
            </Box>
          </label>
        </Container>
      )}
    </>
  );
};

export default DragAndDropNeumorphic;
