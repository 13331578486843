import { Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLoaderContext } from '../../contexts/LoaderContext';

function Summary({ summaryContent }) {

  return (
    <>
    <Typography
    variant="body1"
    component="p"
    style={{
      marginBottom: "1rem",
      marginLeft: "3rem",
    }}
  >
    Summary
  </Typography>
  <Typography
    variant="body2"
    component="p"
    style={{
      marginBottom: "1rem",
      marginLeft: "3rem",
    }}
  >
    {summaryContent}
  </Typography>
  </>
  )
}

export default Summary