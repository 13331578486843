import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import axios from "axios";
import { HealthCareBaseURL, colors } from "../../constants/constants";
import { useLoaderContext } from "../../contexts/LoaderContext";
import FixedButton from "../../components/buttons/FixedButton";
import { AppGradientButton } from "../../assets/styled-components/button";

import RefreshIcon from "@mui/icons-material/Refresh";
import { TabPanel } from "../../components/healthcare/TabPanel";
import  MetaDataComponent from '../../components/healthcare/MetaDataComponent'
import SummaryComponent from "../../components/healthcare/SummaryComponent";
import HealthCareApi from "../../api/HealthcareApi";
import {Toaster} from "../../components/toaster/Toaster";


const HealthCareDocDetails = () => {
  const [url, setUrl] = useState<string>("");
  const [metadata, setMetadata] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorValue, setErrorValue] = useState("");
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const location = useLocation();
  const[success, setSuccess] = useState(false);
  const { startLoader, stopLoader } = useLoaderContext();
  const navigate = useNavigate();
  let { fileKey,source } = location.state || {};
  fileKey = fileKey.split("/").pop() || "";
  const [summary_content, setSummaryContent] = useState<any>([]);
  const [isResponseAvailable,setIsResponseAvailable] =useState(false);
  const [isMetadataDisable, setisMetadataDisable] = useState(false);
  const [isSummaryDisable,setIsSummaryDisable] = useState(false);
  const api = HealthCareApi();

  async function getMetadata(fileKey) {
        startLoader();
        let metadataResponse;
        if(source == "db"){
          metadataResponse = await api.fetchMetadatafromDb(fileKey);
            setSuccess(true)
          if(Object.keys(metadataResponse).length==0){
            metadataResponse = await api.fetchMetadata(fileKey);
          }
        }
        else{
          metadataResponse = await api.fetchMetadata(fileKey);
        }
        setMetadata(metadataResponse);
        if(metadataResponse.purpose === "We are working on the document. If it takes too long, please consider regenerating."){
          setisMetadataDisable(true);
        }
        else{
          setisMetadataDisable(false);
        }
        setIsResponseAvailable(true);
      setSuccess(false)
      }

  const closePopup = () => {
    setOpenPopup(false);
  }

  async function getPdfForIFrame() {
    try {
      const signedUrl = await api.fetchSignedURL(fileKey);
      setPdfUrl(signedUrl);
    } catch (error) {
      console.error("Error getting the signed URL:", error);
    }
  }
  async function getSummary(fileKey) {
    try {
      startLoader();
      let summaryResponse;
      if(source == "db"){
        summaryResponse = await api.fetchDocumentSummaryfromDb(fileKey);
        if(Object.keys(summaryResponse).length == 0){
          summaryResponse = await api.fetchDocumentSummary(fileKey);
        }
      }
      else{
        summaryResponse = await api.fetchDocumentSummary(fileKey);
      }
      
      setSummaryContent(summaryResponse);
      if(summaryResponse.summary === "We are working on the document. If it takes too long, please consider regenerating."){
        setIsSummaryDisable(true);
      }
      else{
        setIsSummaryDisable(false);
      }
    } catch (error) {
      console.error("Failed to fetch summary:", error);
    } finally {
      stopLoader();
    }
  }
  function setMetadataCbFn(metadata){
    setMetadata(metadata)
  }
  function setSummaryCbFn(summary){
    console.log("----",summary);
    setSummaryContent(summary)
  }
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setisMetadataDisable(false);
    setIsSummaryDisable(false);
    getMetadata(fileKey);
    getSummary(fileKey);
    getPdfForIFrame();
  }, []);

  return (
    <>
     <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem",
          fontSize: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100vh",
            width: "100%",
          }}
        >
          <Box sx={{ width: "35%" }}>
            <iframe src={url ? url : `${pdfUrl}`} width="100%" height="100%" />
          </Box>

          <Box
            sx={{
              width: "65%",
              maxHeight: "calc(100vh - 70px)",
              overflowY: "auto",
              padding: "16px",
            }}
          >
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                sx={{
                  "& .MuiTab-root": {
                    color: "white", // Text color for all Tabs
                  },
                  "& .Mui-selected": {
                    color: "white", // Text color for the selected Tab
                  },
                }}
              >
                <Tab label="Metadata" />
                <Tab label="Summary" />
              </Tabs>
              <TabPanel value={value} index={0}>
               
              <MetaDataComponent fileKey={fileKey} metadata={metadata} setMetadataCbFn={setMetadataCbFn} isResponseAvailable={isResponseAvailable} isMetadataDisable={isMetadataDisable}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
               <SummaryComponent fileKey={fileKey} summary_content={summary_content} setSummaryCbFn={setSummaryCbFn} isSummaryDisable={isSummaryDisable} />
              </TabPanel>
            </Box>
          </Box>

               </Box>
         <Toaster openPopup={openPopup} handleClose={closePopup} message={success ? "Metadata is retreived":"Something Went Wrong ... "} isSuccessful={success}/>
      </Box>
    </>
  );
};

export default HealthCareDocDetails;
