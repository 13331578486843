import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "../constants/constants";

const MuiTheme = ({ children }) => {
  const theme = createTheme({
    typography: {
      fontFamily: ["Inter"].join(","),
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    "&.Mui-disabled": {
                        color: "grey"
                    }
                }
            }
        },
      MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: "grey"
                    }
                }
            }
        },

    },
    colors,
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiTheme;
