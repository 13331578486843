import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { AppGradientButton } from "../../assets/styled-components/button";
// import { fetchDocumentSummary } from '../../api/HealthcareApi'

import HealthCareApi from "../../api/HealthcareApi";
import replaceUnderscoresWithSpaces from "../../utils/replaceUnderscoresWithSpaces";
import SimpleHeadingAndText from "./SimpleHeadingAndText";
function SummaryComponent({ fileKey, summary_content, setSummaryCbFn, isSummaryDisable }) {
  const api = HealthCareApi();
  
  async function getSummary(fileKey) {
    const summary = await api.fetchDocumentSummary(fileKey);
    setSummaryCbFn(summary);
  }
  async function approveDocSummary() {
    try {
      await api.approveSummary(fileKey, summary_content);
    } catch (error) {
      console.error("Error updating the Summary:", error);
      throw error;
    }
  }
  return (
    <>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flex: "1",
        }}
      >
        <Box>
          <Typography
            sx={{
              width: "500px",
              wordWrap: "break-word",
              wordBreak: "break-all",
              overflowWrap: "break-word",
            }}
          >
            Summary Of {replaceUnderscoresWithSpaces(fileKey)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2, color: "white" }}>
          {!isSummaryDisable && 
          <AppGradientButton
          width={""}
          height={""}
          sx={{ color: "white" }}
          onClick={() => {
            approveDocSummary();
          }}
        >
          Approve Summary
        </AppGradientButton>
          }
          
          <AppGradientButton
            width={""}
            height={""}
            sx={{ color: "white" }}
            onClick={() => getSummary(fileKey)}
          >
            Regenerate Summary
          </AppGradientButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxHeight: "calc(100vh - 70px)",
          overflowY: "auto",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "20px",
            gap: "10px",
            background: "#171F2C",
            padding: "15px 30px 15px 30px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              fontWeight: "300",
              fontSize: 16,
              letterSpacing: 0.5,
              lineHeight: 1.8,
            }}
          >
             <SimpleHeadingAndText heading="Summary" value={summary_content.summary  || ""} />
             <SimpleHeadingAndText heading="Result" value={summary_content.results_and_findings  || ""} />
             <SimpleHeadingAndText heading="Discussion" value={summary_content.discussion_and_interpretation  || ""} />
             <SimpleHeadingAndText heading="Ethical Consideration" value={summary_content.ethical_considerations  || ""} />
             <SimpleHeadingAndText heading="Funding" value={summary_content.funding_and_conflicts_of_interest  || ""} />
             <SimpleHeadingAndText heading="Review" value={summary_content.peer_review  || ""} />

          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SummaryComponent;
