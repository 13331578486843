import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React from 'react'
import { colors } from "../../constants/constants";

export const ChatWindow = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    // overflowY: "scroll",
    width: "96%",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    padding: "30px",
  }));

  export const Containerr = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    margin: "20px 0px 00px 0px",
    width: "70%",
    background: colors.appChatWindowColor,
    borderRadius: "10px",
    //height: "70vh",
    alignItems: "center",
  }));

  export const ParentContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    // border: "1px solid white",
  }));