import {Box, Typography} from "@mui/material";

export const CatalogCard = ({data,collection}) => {
    console.log(data)
    console.log(collection)
    return(
        <Box
            sx={{
                border:"1px solid white",
                padding:2,
                marginBottom:2,
                borderRadius:3,
                borderColor:"rgba(228, 219, 233, 0.25)",
                display:"flex",
                flexDirection:collection == "ProductsFAQ" ? "row" : "column",
                gap:3
            }}
        >

            {
                collection == "ProductFAQ" && (
                    <Box sx={{
                        padding:2
                    }}>
                        <Typography sx={{
                            fontSize:"22px",
                            paddingBottom:2,
                            fontWeight:"500",
                            opacity:0.7
                        }}>Suggestions</Typography>

                      <Typography>{data.answer}</Typography>

                    </Box>
                )
            }

            {
                collection == "ProductsFAQ" && (
                    <>
                        <img src={data.image_link} width="150px" height="150px"/>
                        <Box>
                           <Typography>Name : {data.name}</Typography>
                            <Typography>Size : {data.size}</Typography>
                            <Typography>Source link : <a href={data.source_link} style={{color:"#0283c5"}}> {data.name}</a></Typography>
                            <Typography>Price : {data.price}</Typography>
                            <Typography>Summary: {data.description}</Typography>
                        </Box>

                    </>
                )
            }



        </Box>
    )
}
