import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

const DocumentSummaryPage = () => {
    const [fileNameValue, setFileNameValue] = useState<string>('')
    const [summary_content, setSummaryContent] = useState<any[]>([])
    const [url,setUrl] = useState<string>('')

    const location = useLocation()
    useEffect(() => {
        if(location.state !== undefined){
            setSummaryContent(location.state.summary)
            if(location.state.url)
            {
                setUrl(location.state.url)
            }
            else
            {
                setFileNameValue(location.state.fileName)
                console.log(`https://d191lewf4yv99g.cloudfront.net/uploaded/${fileNameValue}`)
            }

        }

    }, [location.state]);
    return (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            height: "100vh",
        }}
    >
        <Box sx={{width:"35%"}}>
           <iframe src={url ? url : `https://d191lewf4yv99g.cloudfront.net/uploaded/${fileNameValue}`} width="100%" height="100%"/>
        </Box>

        <Box sx={{width:"65%", maxHeight: 'calc(100vh - 70px)', overflowY: 'auto'}}>
            {summary_content?.map((content,index) => {
                console.log(content.metadata)
                return (
                    <Box key={index} sx={{display:"flex",flexDirection:"column",margin:"20px", gap:"10px" , background:"#171F2C",padding:"15px 30px 15px 30px" , borderRadius:"10px"}}>
                        <Typography sx={{fontWeight: "500", fontSize: 20}}>{content.query}</Typography>
                        <Typography variant="body2" sx={{fontWeight: "300", letterSpacing:0.5, textIndent:40 }}>{content.summary}</Typography>
                        {/*<Typography variant="body2" sx={{fontWeight:"500",background:"linear-gradient(90deg,#008F7A 0%,  #0081CF 100%)", WebkitBackgroundClip: 'text',*/}
                        {/*    WebkitTextFillColor: 'transparent',*/}
                        {/*    MozBackgroundClip: 'text',*/}
                        {/*    MozTextFillColor: 'transparent'}}>Citation - {content.metadata}</Typography>*/}
                        <Stack direction="row" spacing={1} margin={"3px 0px 10px 0px"}>
                            {/* <Typography variant="subtitle1" sx={{fontWeight: "400" , paddingTop:"7px" , marginRight:"30px"}}>References  </Typography> */}

                            { content.metadata.map((pagenumber) => {
                               return(  <Chip label={`Page ${pagenumber}`} variant="filled" sx={{color:"white", marginTop:20, background:
                                            "transparent linear-gradient(90deg, #0081CF 0%, #008F7A 100%) 0% 0% no-repeat padding-box"}}/>)

                            })}
                        </Stack>
                    </Box>
                )
            })}
        </Box>

     </Box>
  );
}

export default DocumentSummaryPage;
