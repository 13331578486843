import { useContext } from 'react';
import UplopadDocContext from '../contexts/UploadDocContext';
import { assertContextScope } from '../utils/context-utils';

const useUploadDocContext = () => {
  const context = useContext(UplopadDocContext);
  assertContextScope(context, 'UploadDocContext', 'useUploadDocContext');
  return {
    error: context[0],
    setError: context[1],
    uploaded: context[2],
    setUploaded: context[3],
    formData: context[4],
    setFormData: context[5],
  };
};

export default useUploadDocContext;
