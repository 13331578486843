import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import LinearProgress from "@mui/material/LinearProgress";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { colors } from "../../constants/constants";
import { AppGradientButton } from "../../assets/styled-components/button";
import presidioLogo from "../../assets/icons/presidio.svg";
import nhlLogo from "../../assets/icons/nhl.svg";
// import bell from '../../assets/icons/bell.svg';
import useLoadingContext from "../../hooks/useLoadingContext";
import useAuthContext from "../../hooks/useAuthContext";
import SettingsService from "../../services/SettingsService";
import { useEffect } from "react";
import { Avatar } from "@mui/material";

const drawerWidth = 240;
// const navItems = [{name:"Home",path:"/dashboard"}, { name: "Settings", path: "/settings" }]

export default function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isAuthenticated, setIsAuthenticated } = useAuthContext();
  const [logo, setLogo] = React.useState("");
  const [clientName, setClientName] = React.useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.clear();
    navigate("/");
  };

  const checkIfLoggedIn = () => {
    try {
      const isLoggedIn: string = localStorage.getItem("auth") || "";

      return isLoggedIn === "true";
    } catch (error) {
      console.error("Error parsing user data:", error);
      return false;
    }
  };

  const getLogo = () => {
    try {
      const isLoggedIn = checkIfLoggedIn();
      if (isLoggedIn) {
        const orgId = JSON.parse(localStorage.getItem("userData")).org_id;

        SettingsService.getOrganizationById(orgId).then((response) => {
          if (response.data?.url) {
            document.title = response.data?.name.toUpperCase();
            setLogo(response.data?.url);
            setClientName(response.data?.name);
          } else {
            return "";
          }
        });
      } else {
        setLogo("");
        setClientName("");
      }
    } catch (error) {
      console.error("Error parsing user data:", error);
      return null;
    }
  };

  useEffect(() => {
    getLogo();
  }, [localStorage.getItem("auth")]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const getUserRole = () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      return userData ? userData?.role : null;
    } catch (error) {
      console.error("Error parsing user data:", error);
      return null;
    }
  };

  const role = getUserRole();

  const navItems =
    isAuthenticated && role === "admin"
      ? [
          { name: "Apps", path: "/dashboard" },
          { name: "Settings", path: "/settings" },
        ]
      : isAuthenticated &&
        (role === "hr" ||
          role === "finance" ||
          role === "internal" ||
          role === "public")
      ? [{ name: "Apps", path: "/dashboard" }]
      : [];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const navigate = useNavigate();
  const { loading } = useLoadingContext();

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        sx={{
          backgroundColor: colors.appNavBgColor,
          height: "70px",
        }}
        component="nav"
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            "@media all": {
              minHeight: 70,
            },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                //justifyContent:"space-between",
                alignItems: "center",
                width: "60%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {logo && (
                  <img
                    src={logo}
                    alt="icon"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                )}
                <Typography
                  component="div"
                  sx={{
                    fontSize: "20px",
                    display: { xs: "none", sm: "block" },
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                >
                  {clientName.toUpperCase()}
                </Typography>
              </Box>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {navItems.map((item) => (
                  <Button
                    key={item.name}
                    sx={{ color: "#fff" }}
                    onClick={() => navigate(item.path)}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {userData &&
              userData["org_id"] == "9987f006-8ad8-4bd8-8cb8-6d6d4a6b785e" ? (
                <Box
                  sx={{
                    width: "180px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Avatar alt="Profile Picture" />
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {userData["name"]}
                      </Typography>

                      {userData["role"] == "public" ? (
                        <Typography
                          sx={{ color: "gray", fontSize: "0.875rem" }}
                        >
                          Physician
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ color: "gray", fontSize: "0.875rem" }}
                        >
                          Health Advisor
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "250px",
                  }}
                >
                  <img
                    src={presidioLogo}
                    alt="icon"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginRight: "10px",
                    }}
                  />
                </Box>
              )}

              {/* {isAuthenticated && (
                <AppGradientButton width="158px" height="40px">
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: colors.appWhite,
                    }}
                    onClick={() => navigate('/upload-doc')}
                  >
                    + New Analysis
                  </Typography>
                </AppGradientButton>
              )} */}
              {isAuthenticated && (
                <AppGradientButton
                  width="158px"
                  height="40px"
                  onClick={handleLogout}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.appWhite,
                    }}
                  >
                    Logout
                  </Typography>
                </AppGradientButton>
              )}
              {/*{!isAuthenticated && (
                <AppGradientButton
                  width="158px"
                  height="40px"
                  onClick={() => navigate("/")}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.appWhite,
                    }}
                  >
                    Login
                  </Typography>
                </AppGradientButton>
                  )}*/}

              {/* <img
                src={bell}
                alt="notification"
                style={{ width: '26px', height: '26px' }}
              /> */}

              {/* <AccountCircleIcon
                  sx={{ fontSize: '50px', cursor: 'pointer' }}
                /> */}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box component="main" sx={{ pt: 1, width: "100%" }}>
        <Toolbar />
        {loading && <LinearProgress />}
      </Box>
    </Box>
  );
}
